import React from 'react'

export default function TickLarge() {
  return (
    <svg
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m40 0c-7.9112 0-15.6448 2.34596-22.2228 6.74121-6.578 4.39529-11.70486 10.64239-14.73236 17.95149-3.0275086 7.309-3.819642 15.3517-2.276233 23.1109 1.543413 7.7592 5.353043 14.8866 10.947093 20.4807 5.5941 5.5941 12.7215 9.4037 20.4807 10.9471s15.8019.7513 23.1109-2.2762c7.3091-3.0275 13.5562-8.1544 17.9515-14.7324 4.3952-6.578 6.7412-14.3116 6.7412-22.2228-.0122-10.6049-4.2304-20.772-11.7292-28.2708-7.4988-7.49879-17.6659-11.7169847-28.2708-11.7292zm23.72 26.7846-30.5846 30.7692c-.5739.5779-1.3535.9046-2.1679.9086-.8143.0041-1.5971-.3149-2.1767-.887l-12.4892-12.3077c-.5296-.5816-.8163-1.344-.8013-2.1305.0151-.7864.3308-1.5373.8823-2.0982.5514-.5609 1.2968-.8893 2.0829-.9177.7861-.0285 1.5532.2452 2.1437.7648l10.3077 10.1539 28.44-28.5785c.2831-.2945.6219-.5298.9968-.692.3749-.1623.7784-.2482 1.1869-.2529.4085-.0046.8138.0721 1.1923.2257.3785.1537.7226.3812 1.0123.6692s.5191.6309.6749 1.0085c.1558.3777.2348.7825.2324 1.191-.0023.4085-.086.8125-.2461 1.1883s-.3934.716-.6864 1.0007z"
        fill="#ccff00"
      />
    </svg>
  )
}

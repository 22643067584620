import { asset1, asset2 } from './assets'

const assets = [
  {
    src: asset1,
    x: 0,
    y: 0,
  },
  {
    src: asset2,
    x: 0,
    y: 436.4746,
  },
]

const data = {
  assets: assets,
}

export default data

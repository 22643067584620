import AWS from 'aws-sdk'

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIA4J4OMEQQRDC2XHLJ',
  secretAccessKey:
    process.env.REACT_APP_AWS_ACCESS_KEY || '2UsLN0zP/weyJI/S59pl2g9ArtudttF3e4Rz+kgN',
})

export const S3Bucket = 'nikegloverteam'
export const S3 = new AWS.S3()
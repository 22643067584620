import {
  asset0,
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  asset16,
  asset17,
  asset18,
  layer3d,
  layerShadow,
  nikeLogo,
  nikeLogoOutline,
} from './assets'

const assets = [
  { src: asset0, x: 561.312, y: 1086.968, itemId: 112 },
  { src: asset1, x: 0, y: 0, itemId: 101 },
  { src: asset2, x: 58.876, y: 0.4644, itemId: 102, parentId: 101 },
  { src: asset3, x: 422.2688, y: 0.4644, itemId: 102, parentId: 101 },
  { src: asset4, x: 422.2688, y: 0.4644, itemId: 101 },
  { src: asset5, x: 0.9532, y: 587.1024, itemId: 106 },
  { src: asset6, x: 2.9572, y: 63.1528, itemId: 110, position: 'Right' },
  { src: asset7, x: 121.2344, y: 833.762 },
  { src: asset8, x: 354.8112, y: 1097.2404, itemId: 106 },
  { src: asset9, x: 375.5268, y: 1109.362, defaultColor: '#82808f' },
  { src: asset10, x: 412.0308, y: 1105.802, itemId: 109 },
  { src: asset11, x: 420.8812, y: 1122.2688, itemId: 108, darken: 40 },
  { src: asset12, x: 534.906, y: 1210.9884, itemId: 109 },
  { src: asset13, x: 536.6824, y: 1212.7628, itemId: 108, darken: 40 },
  { src: asset14, x: 194.212, y: 650.134 },
  { src: asset15, x: 557.4624, y: 1077.7556 },
  { src: asset16, x: 332.2208, y: 1039.7896, itemId: 107 },
  { src: asset17, x: 343.5908, y: 71.8008, itemId: 104 },
  { src: asset18, x: 11.9744, y: 72.7476, itemId: 105 },
  { src: nikeLogoOutline, x: 460.3364, y: 697.82, itemId: 101 },
  { src: nikeLogo, x: 464.3364, y: 701.82, itemId: 103 },
]

const data = {
  assets: assets,
  '3dLayer': [
    { src: layer3d, x: -180.71, y: -348.256, globalComposition: 'multiply' },
  ],
  shadow: [
    {
      src: layerShadow,
      x: 269.5029,
      y: 1403.109,
    },
  ],
}

export default data

import React, { forwardRef, InputHTMLAttributes } from 'react'
import classes from './input.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  theme?: 'light' | 'dark'
  label?: string
}

const Input = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const inputProps = { type: 'text', maxLength: 256, ...props }
  const { theme = '', label, className, ...rest } = inputProps

  return (
    <div className={`${classes.container} ${classes[theme]} ${className}`}>
      {label && <label>{label}</label>}
      <input ref={ref} {...rest} />
    </div>
  )
})

export default Input

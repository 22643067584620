import { default as chestProtector } from './chest-protector'
import { default as legGuard } from './leg-guard'
import { swoosh as chestprotectorSwoosh } from './chest-protector/front'
import { swoosh as legguardSwoosh } from './leg-guard/front-left'

const views = [
  chestProtector.config.views[0],
  ...legGuard.config.views,
  {
    id: 'chestprotectorSwoosh',
    name: 'Chestprotector Swoosh',
    productAssets: chestprotectorSwoosh,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'legguardSwoosh',
    name: 'Legguard Swoosh',
    productAssets: legguardSwoosh,
    productItems: [],
    reRender: 0,
  },
]

const tabs = [
  {
    id: 1,
    name: 'Front View',
    prefix: '',
    iconName: 'catcherFront',
    views: [views[0]],
  },
  {
    id: 2,
    name: 'Leg Guard Front',
    prefix: '',
    iconName: 'legFront',
    views: [views[2], views[3]],
    styleProps: [
      { width: '50%', height: '100%' },
      { width: '50%', height: '100%' },
    ],
    aspectRatio: {
      x: 1,
      y: 1,
    },
  },
  {
    id: 3,
    name: 'Leg Guard Side',
    prefix: '',
    iconName: 'legSide',
    views: [views[1], views[4]],
    styleProps: [
      { width: '50%', height: '100%' },
      { width: '50%', height: '100%' },
    ],
    aspectRatio: {
      x: 1,
      y: 0.9,
    },
  },
]

const previewAllTabs = [
  {
    id: 0,
    name: 'Preview All',
    prefix: 'preview',
    iconName: '',
    views: views,
    styleProps: [
      { width: '100%', height: '50%' },
      { width: '28%', height: '50%' },
      { width: '22%', height: '50%' },
      { width: '22%', height: '50%' },
      { width: '28%', height: '50%' },
      {
        width: '258px',
        height: '109px',
        position: 'absolute',
        left: '-10000px',
        top: '0px',
      },
      {
        width: '192px',
        height: '83px',
        position: 'absolute',
        left: '-9000px',
        top: '0px',
      },
    ],
    aspectRatio: {
      x: 1,
      y: 1,
    },
  },
  ...tabs,
]

const fonts = [
  {
    label: 'Script',
    value: 'BrushScript',
  },
  {
    label: 'Block',
    value: 'CenturyGothic',
  },
]

const config = {
  fonts,
  views,
  tabs,
  previewAllTabs,
}

const data = {
  config,
  front: chestProtector.front,
  outside: legGuard.outside,
  inside: legGuard.inside,
  frontRight: legGuard.frontRight,
  frontLeft: legGuard.frontLeft,
  chestprotectorSwoosh: chestprotectorSwoosh,
  legguardSwoosh: legguardSwoosh,
}

export default data

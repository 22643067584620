import {
  alabasterSuede,
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  blackSuede,
  cinderOrangeSuede,
} from './assets'

const assets: any = [
  {
    src: asset1,
    x: 0,
    y: 0,
    itemId: 101,
  },
  {
    src: asset9,
    x: 36.9837,
    y: 43.1103,
  },
  {
    src: asset8,
    x: 4.0698,
    y: 233.0748,
    itemId: 102,
  },
  {
    src: asset7,
    x: 146.0472,
    y: 272.8392,
    itemId: 103,
  },
  {
    src: asset5,
    x: -3.8736,
    y: 38.7996,
    itemId: 110,
  },
  {
    src: asset4,
    x: 516.0489,
    y: 90.5643,
    itemId: 109,
  },

  {
    src: asset2,
    x: 16.1202,
    y: 96.1548,
    itemId: 108,
  },
  {
    src: asset3,
    x: 420.9006,
    y: 345.0711,
    itemId: 101,
  },

  {
    src: asset6,
    x: 826.3485,
    y: 255.5796,
    itemId: 111,
  },

  { itemId: 114, x: 310, y: 395, fontSize: 48, angle: -6 },
]

const data = {
  assets: assets,
  'black-suede': [{ src: blackSuede, x: 0, y: 0, zindex: 1 }],
  'cinder-orange-suede': [{ src: cinderOrangeSuede, x: 0, y: 0, zindex: 1 }],
  'alabaster-suede': [{ src: alabasterSuede, x: 0, y: 0, zindex: 1 }],
}

export default data

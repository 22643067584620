import React from 'react'
import classes from './tabs.module.scss'

interface Props {
  className?: string
  theme?: 'light' | 'dark'
  list: any[]
  selectedItem: any
  onSelect: (item: any) => void
}

export default function Tabs({
  className = '',
  theme = 'light',
  list,
  selectedItem,
  onSelect,
}: Props) {
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={`${classes.tabsList} ${classes[theme]}`}>
        {list.map((tab: any) => {
          return (
            <div
              key={tab.id}
              className={`${classes.tab} ${
                tab === selectedItem ? classes.active : ''
              }`}
              onClick={() => onSelect(tab)}>
              {tab.label}
            </div>
          )
        })}
      </div>
    </div>
  )
}

import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  layer3d,
  layerShadow,
  nikeLogo,
} from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 101 },
  {
    src: asset2,
    x: 86.8092,
    y: 40.7584,
    // itemId: 101,
    globalComposition: 'multiply',
  },
  // {
  //   src: asset3,
  //   x: 90.8252,
  //   y: 44.6144,
  //   // itemId: 101,
  //   globalComposition: 'multiply',
  // },
  { src: asset4, x: 241.5172, y: 579.4032, itemId: 102 },
  { src: asset5, x: -0.1744, y: 61.154, itemId: 110, position: 'Right' },
  { src: asset6, x: 320.9816, y: 1165.9696, itemId: 106 },
  { src: asset7, x: 344.7008, y: 72.2216, itemId: 105 },
  { src: asset8, x: 8.8668, y: 67.7904, itemId: 104 },
  { src: asset9, x: 327.1092, y: 1156.978 },
  { src: asset10, x: 342.6448, y: 1145.908, itemId: 107 },
  { src: asset11, x: 340.544, y: 1198.9416, itemId: 109 },
  { src: asset12, x: 357.8348, y: 1210.8032, itemId: 108, darken: 40 },
  { src: asset13, x: 485.7832, y: 1204.792, itemId: 109 },
  { src: asset14, x: 497.6708, y: 1204.792, itemId: 108, darken: 40 },
  { src: asset15, x: 532.7112, y: 1217.2852 },
]

const data = {
  assets: assets,
  'nike-solid': [{ src: nikeLogo, x: 428.5008, y: 805.0464, itemId: 103 }],
  'jordan-solid': [{ src: nikeLogo, x: 428.5008, y: 805.0464, itemId: 103 }],
  '3dLayer': [
    {
      src: layer3d,
      x: -181.06,
      y: -347.0364,
      globalComposition: 'multiply',
    },
  ],
  shadow: [
    {
      src: layerShadow,
      x: 269.5029,
      y: 1433.109,
    },
  ],
}

export default data

import React from 'react'

export default function PreviewAll() {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m7.706 24h-4.41006c-1.81045 0-3.29594-1.4769-3.29594-3.2769v-4.5231c0-1.8 1.48549-3.2769 3.29594-3.2769h4.41006c1.81044 0 3.2959 1.4769 3.2959 3.2769v4.5231c0 1.8-1.48546 3.2769-3.2959 3.2769zm-4.36364-8.7692c-.55706 0-.97486.4154-.97486.9692v4.5231c0 .5538.4178.9692.97486.9692h4.41006c.55706 0 .97485-.4154.97485-.9692v-4.5231c0-.5538-.41779-.9692-.97485-.9692zm17.36174-4.1539h-4.4101c-1.8104 0-3.2959-1.4769-3.2959-3.2769v-4.52308c0-1.8 1.4855-3.27692 3.2959-3.27692h4.4101c1.8104 0 3.2959 1.47692 3.2959 3.27692v4.52308c0 1.8-1.4855 3.2769-3.2959 3.2769zm-4.3637-8.76921c-.557 0-.9748.41539-.9748.96923v4.52308c0 .55385.4178.96923.9748.96923h4.4101c.557 0 .9748-.41538.9748-.96923v-4.52308c0-.55384-.4178-.96923-.9748-.96923zm-8.6344 8.76921h-4.41006c-1.81045 0-3.29594-1.4769-3.29594-3.2769v-4.52308c0-1.8 1.48549-3.27692 3.29594-3.27692h4.41006c1.81044 0 3.2959 1.47692 3.2959 3.27692v4.52308c0 1.8-1.48546 3.2769-3.2959 3.2769zm-4.36364-8.76921c-.55706 0-.97486.41539-.97486.96923v4.52308c0 .55385.4178.96923.97486.96923h4.41006c.55706 0 .97485-.41538.97485-.96923v-4.52308c0-.55384-.41779-.96923-.97485-.96923zm17.36174 21.69231h-4.4101c-1.8104 0-3.2959-1.4769-3.2959-3.2769v-4.5231c0-1.8 1.4855-3.2769 3.2959-3.2769h4.4101c1.8104 0 3.2959 1.4769 3.2959 3.2769v4.5231c0 1.8-1.4855 3.2769-3.2959 3.2769zm-4.3637-8.7692c-.557 0-.9748.4154-.9748.9692v4.5231c0 .5538.4178.9692.9748.9692h4.4101c.557 0 .9748-.4154.9748-.9692v-4.5231c0-.5538-.4178-.9692-.9748-.9692z"
        fill="#dadada"
      />
    </svg>
  )
}

import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  nikeLogo,
  nikeLogoShadow,
  nikeLogoStitch,
  nikeLogoOutline,
  fingerPadIndex,
  fingerPadIndexStitch,
  fingerPadMiddle,
  fingerPadMiddleStitch,
  fingerSleeveIndex,
  fingerSleeveIndexStitch,
  fingerSleeveMiddle,
  fingerSleeveMiddleStitch,
  blackMesh,
  blackSuede,
  cinderOrangeSuede,
  alabasterSuede,
} from './assets'

const assets: any = [
  {
    src: asset1,
    x: 0,
    y: 0,
    itemId: 101,
  },
  {
    src: asset2,
    x: 0.4344,
    y: 239.0463,
    itemId: 102,
  },
  {
    src: asset3,
    x: 146.2788,
    y: 276.0771,
    itemId: 103,
  },
  {
    src: asset4,
    x: 33.5967,
    y: 302.2335,
    itemId: 101,
  },
  {
    src: asset5,
    x: 37.2153,
    y: 46.1949,
  },
  {
    src: asset6,
    x: 736.4526,
    y: 175.5945,
    itemId: 101,
  },
  {
    src: asset7,
    x: 508.0803,
    y: 86.2287,
    itemId: 111,
  },
  {
    src: asset8,
    x: 679.1391,
    y: 150.1851,
    itemId: 105,
  },
  {
    src: asset9,
    x: -3.642,
    y: 41.8842,
    itemId: 110,
  },
  {
    src: asset10,
    x: 516.2805,
    y: 85.5729,
    itemId: 109,
  },
  {
    src: asset11,
    x: 16.3518,
    y: 99.2379,
    itemId: 108,
  },
  {
    src: asset12,
    x: 421.1322,
    y: 348.1464,
    itemId: 101,
  },
  { src: nikeLogoOutline, x: 774.4428, y: 194.0055, itemId: 107 },
  {
    src: nikeLogoStitch,
    x: 776.4468,
    y: 196.1175,
    itemId: 107,
    darken: 40,
  },
  { src: nikeLogo, x: 778.3467, y: 195.9321, itemId: 106 },
  {
    src: nikeLogoShadow,
    x: 778.35,
    y: 195.9303,
    globalComposition: 'multiply',
  },
  { itemId: 114, x: 310, y: 395, fontSize: 48, angle: -6 },
]

const data = {
  assets: assets,
  'finger-pad-index-finger': [
    { src: fingerPadIndex, x: 350.661, y: -1.3005, itemId: 101 },
    { src: fingerPadIndexStitch, x: 356.0574, y: 2.7, itemId: 111 },
  ],
  'finger-pad-middle-finger': [
    { src: fingerPadMiddle, x: 411.6294, y: 74.7135, itemId: 101 },
    { src: fingerPadMiddleStitch, x: 415.6866, y: 80.46, itemId: 111 },
  ],
  'finger-sleeve-index-finger': [
    { src: fingerSleeveIndex, x: 288.2514, y: -33.366, itemId: 101 },
    { src: fingerSleeveIndexStitch, x: 291.9351, y: -1.1922, itemId: 111 },
  ],
  'finger-sleeve-middle-finger': [
    { src: fingerSleeveMiddle, x: 355.5645, y: 43.1037, itemId: 101 },
    { src: fingerSleeveMiddleStitch, x: 359.2485, y: 75.2775, itemId: 111 },
  ],
  'black-mesh': [{ src: blackMesh, x: 1.4025, y: 3.6921, zindex: 1 }],
  'black-suede': [{ src: blackSuede, x: 26.4162, y: 1.338, zindex: 1 }],
  'cinder-orange-suede': [
    { src: cinderOrangeSuede, x: 13.6527, y: -0.6258, zindex: 1 },
  ],
  'alabaster-suede': [
    { src: alabasterSuede, x: 13.6527, y: -0.6258, zindex: 1 },
  ],
}

export default data

import React, { forwardRef, TextareaHTMLAttributes } from 'react'
import classes from './textarea.module.scss'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  theme?: 'light' | 'dark'
  label?: string
}

const Input = forwardRef<HTMLTextAreaElement, Props>((props: Props, ref) => {
  const { theme = 'light', label, ...rest } = props

  return (
    <div className={`${classes.container} ${classes[theme]}`}>
      {label && <label>{label}</label>}
      <textarea ref={ref} {...rest}></textarea>
    </div>
  )
})

export default Input

import React from 'react'
import SwitchComponent from 'react-switch'
import classes from './switch.module.scss'
type Props = {
  theme?: 'light' | 'dark'
  label: string
  checked: boolean
  onChange: (e: any) => void
}

export default function Switch({
  theme = 'light',
  label,
  checked,
  onChange,
}: Props) {
  return (
    <label className={`${classes.container} ${classes[theme]}`}>
      <span>{label}</span>
      <SwitchComponent
        onChange={onChange}
        checked={checked}
        offColor={theme === 'light' ? '#f2f2f2' : '#242424'}
        onColor={theme === 'light' ? '#f2f2f2' : '#242424'}
        onHandleColor="#ccff00"
        offHandleColor="#a6a6a6"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        height={24}
        width={54}
      />
    </label>
  )
}

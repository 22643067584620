import React from 'react'

export default function Cube() {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m20.5 7.27783-8.5 4.72227m0 0-8.50003-4.72227m8.50003 4.72227v9.5m9-5.4415v-8.11707c0-.34264 0-.51396-.0505-.66676-.0446-.13518-.1177-.25926-.2141-.36395-.1091-.11834-.2588-.20154-.5584-.36794l-7.4-4.11111c-.2836-.15756-.4254-.23634-.5755-.26723-.133-.02733-.27-.02733-.4029 0-.1502.03089-.292.10967-.5756.26723l-7.40003 4.11111c-.29952.1664-.44928.2496-.55834.36794-.09647.10469-.16948.22877-.21415.36395-.05048.1528-.05048.32412-.05048.66676v8.11707c0 .3427 0 .514.05048.6668.04467.1352.11768.2593.21415.3639.10906.1184.25882.2016.55834.368l7.40003 4.1111c.2836.1576.4254.2363.5756.2672.1329.0274.2699.0274.4029 0 .1501-.0309.2919-.1096.5755-.2672l7.4-4.1111c.2996-.1664.4493-.2496.5584-.368.0964-.1046.1695-.2287.2141-.3639.0505-.1528.0505-.3241.0505-.6668z"
        stroke="#e9edf0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
    </svg>
  )
}

import {
  asset1,
  asset1_1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  asset16,
  asset17,
  asset18,
  asset19,
  asset20,
  asset21,
  asset22,
  asset23,
  asset24,
  asset25,
  asset26,
  asset27,
  asset28,
  asset29,
  asset30,
  asset31,
  asset32,
  asset33,
  asset34,
  asset35,
  nikeLogo,
  nikeLogoOutline,
  placeholder,
} from './assets'

const assets = [
  {
    src: asset1,
    x: 9.9008,
    y: 600.1952,
    itemId: 213,
  },
  {
    src: asset1_1,
    x: 9.9008,
    y: 600.1952,
    itemId: 213,
  },
  {
    src: asset2,
    x: 202.0712,
    y: 1240.7464,
  },
  {
    src: asset3,
    x: 9.458,
    y: 600.18,
    itemId: 209,
  },
  {
    src: asset4,
    x: 87.6452,
    y: 1259.8428,
    itemId: 209,
  },
  {
    src: asset5,
    x: 99.4184,
    y: 1259.8428,
    itemId: 213,
  },
  {
    src: asset6,
    x: 99.4192,
    y: 1259.8428,
    itemId: 213,
  },
  {
    src: asset7,
    x: 81.3032,
    y: 1570.1008,
  },
  {
    src: asset8,
    x: 125.2132,
    y: 1611.9392,
    itemId: 201,
  },
  {
    src: asset9,
    x: 160.124,
    y: 1687.7024,
    globalComposition: 'multiply',
  },
  {
    src: asset10,
    x: 125.348,
    y: 1657.8704,
  },
  {
    src: asset11,
    x: 102.7028,
    y: 1498.1764,
    itemId: 201,
  },
  {
    src: asset12,
    x: 135.6948,
    y: 1511.788,
    globalComposition: 'multiply',
  },
  {
    src: asset13,
    x: 106.7992,
    y: 1502.2912,
  },
  {
    src: asset14,
    x: 35.31,
    y: 610.074,
    itemId: 201,
  },
  {
    src: asset15,
    x: 46.7936,
    y: 675.8128,
    globalComposition: 'multiply',
  },
  {
    src: asset16,
    x: 77.332,
    y: 782.9884,
    itemId: 213,
  },
  {
    src: asset17,
    x: 10.1328,
    y: 708.2092,
  },
  {
    src: asset18,
    x: 22.1172,
    y: 0,
    itemId: 209,
  },
  {
    src: asset19,
    x: 30.1892,
    y: 16.6672,
    itemId: 213,
  },
  {
    src: asset20,
    x: 28.8724,
    y: 20.7556,
    itemId: 201,
  },
  {
    src: asset21,
    x: 34.8368,
    y: 42.9944,
    globalComposition: 'multiply',
  },
  {
    src: asset22,
    x: 99.7872,
    y: 125.47,
    itemId: 213,
  },
  {
    src: asset23,
    x: 38.9376,
    y: 114.1568,
  },
  {
    src: asset24,
    x: 20.7108,
    y: 312.3664,
    itemId: 213,
  },
  {
    src: asset25,
    x: 20.7108,
    y: 312.3664,
    itemId: 209,
  },
  {
    src: asset26,
    x: 25.8812,
    y: 319.6872,
  },
  {
    src: asset27,
    x: 34.7428,
    y: 330.1252,
    itemId: 201,
  },
  {
    src: asset28,
    x: 37.2616,
    y: 359.0908,
    globalComposition: 'multiply',
  },
  {
    src: asset29,
    x: 53.2312,
    y: 337.2676,
  },
  {
    src: asset30,
    x: 93.2552,
    y: 640.0264,
  },
  {
    src: asset31,
    x: 27.9992,
    y: 166.6328,
    itemId: 209,
  },
  {
    src: asset32,
    x: 34.0756,
    y: 171.4112,
    itemId: 201,
  },
  {
    src: asset33,
    x: 94.5772,
    y: 196.7484,
    globalComposition: 'multiply',
  },
  {
    src: asset34,
    x: 87.5848,
    y: 215.6252,
    itemId: 213,
  },
  {
    src: asset35,
    x: 0,
    y: 110.2448,
  },
  {
    src: nikeLogoOutline,
    x: 146.466,
    y: 269.2984,
    itemId: 207,
    defaultColor: '#222222',
  },
  {
    src: nikeLogo,
    x: 148.466,
    y: 271.2984,
    itemId: 206,
  },
  {
    src: placeholder,
    x: 102,
    y: 1418,
    itemId: 208,
  },
]

const data = {
  assets: assets,
}

export default data

import {
  asset1,
  asset1_1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  asset16,
  asset17,
  asset18,
  asset19,
  asset20,
  asset21,
  asset22,
  asset23,
  asset24,
  asset25,
  asset26,
  asset27,
  asset28,
  asset29,
  asset30,
  asset31,
  asset32,
  asset33,
  asset34,
  asset35,
  nikeLogo,
  nikeLogoOutline,
  placeholder,
} from './assets'

const assets = [
  {
    src: asset1,
    x: 16.1028,
    y: 600.1952,
    itemId: 213,
  },
  { src: asset1_1, x: 16.1028, y: 600.1952, itemId: 213 },
  {
    src: asset2,
    x: 208.2788,
    y: 1240.7464,
  },
  {
    src: asset3,
    x: 15.6604,
    y: 600.18,
    itemId: 209,
  },
  {
    src: asset4,
    x: 95.5404,
    y: 1259.8428,
    itemId: 209,
  },
  {
    src: asset5,
    x: 107.3136,
    y: 1259.8428,
    itemId: 213,
  },
  {
    src: asset6,
    x: 107.3144,
    y: 1259.8428,
    itemId: 213,
  },
  {
    src: asset7,
    x: 87.2204,
    y: 1570.1008,
  },
  {
    src: asset8,
    x: 131.4564,
    y: 1611.9392,
    itemId: 201,
  },
  {
    src: asset9,
    x: 167.2552,
    y: 1687.7024,
    globalComposition: 'multiply',
  },
  {
    src: asset10,
    x: 131.528,
    y: 1657.8704,
  },
  {
    src: asset11,
    x: 108.1804,
    y: 1498.1764,
    itemId: 201,
  },
  {
    src: asset12,
    x: 142.826,
    y: 1511.788,
    globalComposition: 'multiply',
  },
  {
    src: asset13,
    x: 112.2768,
    y: 1502.2912,
  },
  {
    src: asset14,
    x: 41.5124,
    y: 610.074,
    itemId: 201,
  },
  {
    src: asset15,
    x: 53.174,
    y: 675.8128,
    globalComposition: 'multiply',
  },
  {
    src: asset16,
    x: 82.7696,
    y: 782.9884,
    itemId: 213,
  },
  {
    src: asset17,
    x: 15.148,
    y: 708.2092,
  },
  {
    src: asset18,
    x: 0,
    y: 0,
    itemId: 209,
  },
  {
    src: asset19,
    x: 4.7616,
    y: 16.6672,
    itemId: 213,
  },
  {
    src: asset20,
    x: 34.5068,
    y: 20.7556,
    itemId: 201,
  },
  {
    src: asset21,
    x: 39.3592,
    y: 42.9944,
    globalComposition: 'multiply',
  },
  {
    src: asset22,
    x: 103.096,
    y: 125.47,
    itemId: 213,
  },
  {
    src: asset23,
    x: 412.454,
    y: 114.1568,
  },
  {
    src: asset24,
    x: 25.0664,
    y: 312.3664,
    itemId: 213,
  },
  {
    src: asset25,
    x: 25.0664,
    y: 312.3664,
    itemId: 209,
  },
  {
    src: asset26,
    x: 30.2364,
    y: 319.6872,
  },
  {
    src: asset27,
    x: 39.0984,
    y: 330.1252,
    itemId: 201,
  },
  {
    src: asset28,
    x: 41.6172,
    y: 359.0908,
    globalComposition: 'multiply',
  },
  {
    src: asset29,
    x: 57.5868,
    y: 337.2676,
  },
  {
    src: asset30,
    x: 100.9476,
    y: 640.0264,
  },
  {
    src: asset31,
    x: 33.6552,
    y: 166.6328,
    itemId: 209,
  },
  {
    src: asset32,
    x: 39.7656,
    y: 171.4112,
    itemId: 201,
  },
  {
    src: asset33,
    x: 102.3748,
    y: 196.7484,
    globalComposition: 'multiply',
  },
  {
    src: asset34,
    x: 95.3824,
    y: 215.6252,
    itemId: 213,
  },
  {
    src: asset35,
    x: 0.9948,
    y: 110.2448,
  },
  {
    src: nikeLogoOutline,
    x: 150.1564,
    y: 269.2984,
    itemId: 207,
    defaultColor: '#222222',
  },
  {
    src: nikeLogo,
    x: 152.1564,
    y: 271.2984,
    itemId: 206,
  },
  {
    src: placeholder,
    x: 0,
    y: 0,
    itemId: 208,
  },
]

const data = {
  assets: assets,
}

export const swoosh = {
  assets: [
    {
      src: nikeLogoOutline,
      x: 0,
      y: 0,
      itemId: 207,
      defaultColor: '#222222',
    },
    {
      src: nikeLogo,
      x: 2,
      y: 2,
      itemId: 206,
    },
  ],
}

export default data

import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ImageLoad } from 'components'
import classes from './orders.module.scss'

interface Props {
  orders: any[]
  onSelect: (order: any) => void
}

export default function OrdersList({ orders = [], onSelect }: Props) {
  const getOrderCode = (order: any) => {
    const { orderCode, orderColorNumber, productName } = order

    if (productName.indexOf('Fielding Glove') > -1) {
      return productName.replace('Fielding Glove ', '')
    } else if (
      productName === 'Chest Protector' ||
      productName === 'Leg Guard'
    ) {
      return "Catcher's Gear"
    } else {
      const name: string[] = []

      name.push(orderCode)
      if (orderColorNumber) {
        name.push(orderColorNumber)
      }

      return productName || name.join(' ')
    }
  }

  const getTrackStatusClass = (trackStatus: string) => {
    switch (trackStatus) {
      case 'In-progress':
        return classes.inProgress
      case 'Submitted':
        return classes.submitted
      default:
        return classes.new
    }
  }

  return (
    <>
      {orders.map((order: any) => {
        return (
          <Link
            to={order.orderId}
            key={order.orderId}
            className={classes.productCard}
            onClick={() => onSelect(order)}>
            <div className={classes.thumbnail}>
              <ImageLoad
                src={order.thumbnailLogo || order.productProfileLogo}
              />
            </div>
            <div className={classes.productInfo}>
              <div className={classes.statusInfo}>
                <div
                  className={`${classes.status} ${getTrackStatusClass(
                    order.trackStatus,
                  )}`}>
                  {order.trackStatus}
                </div>
              </div>
              <div className={classes.nameNfamily}>
                <div className={classes.name}>{getOrderCode(order)}</div>
                {order.family && (
                  <div
                    className={`${
                      classes.family
                    } ${order.family.toLowerCase()}`}>
                    {order.family}
                  </div>
                )}
              </div>
              <div className={classes.sizeNdate}>
                {order.gloveSize && (
                  <div className={classes.size}>{order.gloveSize + "''"}</div>
                )}
                <div className={classes.date}>
                  {'Last modified: ' +
                    (order.updatedAt
                      ? moment(order.updatedAt).format('MMM DD')
                      : '--')}
                </div>
              </div>
            </div>
          </Link>
        )
      })}
    </>
  )
}

import { gql } from '@apollo/client'

//read
export const GET_SIZES = gql`
  query gloveSizes($sportId: String, $position: String) {
    gloveSizes(sportId: $sportId, position: $position) {
      _id
      size
    }
  }
`

export const GET_PLAYER_ORDERS = gql`
  query playerOrders($playerCode: String) {
    playerOrders(playerCode: $playerCode) {
      orders {
        orderId
        orderCode
        orderColorNumber
        season
        thumbnailLogo
        productProfileLogo
        productName
        gloveSize
        family
        status
        orderStatus
        trackStatus
        updatedAt
      }
    }
  }
`

export const GET_TEAM_ORDERS = gql`
  query teamOrders($teamId: String) {
    teamOrders(teamId: $teamId) {
      orders {
        orderId
        orderCode
        orderColorNumber
        season
        thumbnailLogo
        productProfileLogo
        productName
        gloveSize
        family
        status
        orderStatus
        trackStatus
        updatedAt
      }
    }
  }
`

export const GET_ORDER = gql`
  query order($orderId: String) {
    order(orderId: $orderId) {
      _id
      orderId
      orderCode
      orderColorNumber
      playerId
      sportId
      teamId
      teamName
      gloveSize
      legGuardSize
      throwingHand
      family
      productId
      orderStatus
      trackStatus
      statusId
      season
      owner
      groupName
      fileName
      styleName
      secondFileName
      secondStyleName
      colorway
      secondColorway
      updatedAt
      product {
        _id
        name
      }
      player {
        _id
        name
        playerCode
        position
        number
      }
      playerLogo {
        _id
        name
        teamId
        aiName
        aiLogo
        logo
        status
      }
      playerArtWork {
        _id
        playerId
        name
        style
        family
        status
        palmPattern
        bohPattern
        dieCastLeftPattern
        dieCastRightPattern
        dieCastLeftPatternSmall
        dieCastRightPatternSmall
        artwork
      }
      teamColor {
        _id
        name
        code
        status
      }
      teamLogo {
        _id
        name
        teamId
        aiName
        aiLogo
        logo
        status
      }
      preview {
        viewId
        url
      }
      productItems {
        _id
        orderId
        itemId
        itemType
        item
        playerItem
        pdfItem
        value
        options
        optionalColors
        primaryColors
        secondaryColors
        tertiaryColors
        selectOptions
        pattern
        material
        brand
        logos
        symmetricalLogos
        customPattern
        materialOptions
        children
        supplier
        component
        notes
        color
        font
        stroke
        strokeColor
        colorwayIndex
        showEmbroidery
        enableSwooshOutline
        status
      }
      customMessage
      dieCast
      notes
      additionalOptions
    }
  }
`

//update
export const UPDATE_ORDER = gql`
  mutation createOrder(
    $_id: ID
    $playerId: String
    $teamId: String
    $gloveSize: String
    $legGuardSize: String
    $orderStatus: String
    $trackStatus: String
    $thumbnailLogo: String
    $preview: [previewInput]
    $productItems: [productOptions]
    $customMessage: JSON
    $notes: String
    $additionalOptions: JSON
  ) {
    createOrder(
      _id: $_id
      playerId: $playerId
      teamId: $teamId
      gloveSize: $gloveSize
      legGuardSize: $legGuardSize
      orderStatus: $orderStatus
      trackStatus: $trackStatus
      thumbnailLogo: $thumbnailLogo
      preview: $preview
      productItems: $productItems
      customMessage: $customMessage
      notes: $notes
      additionalOptions: $additionalOptions
    ) {
      _id
    }
  }
`

import outside from './outside'
import inside from './inside'
import frontRight from './front-right'
import frontLeft from './front-left'

const views = [
  {
    id: 'outside',
    name: 'Outside',
    iconName: 'legSide',
    productAssets: outside,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'frontRight',
    name: 'Front Right',
    iconName: 'legFront',
    productAssets: frontRight,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'frontLeft',
    name: 'Front Left',
    iconName: 'legFront',
    productAssets: frontLeft,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'inside',
    name: 'Inside',
    iconName: 'legSide',
    productAssets: inside,
    productItems: [],
    reRender: 0,
  },
]

const tabs = [
  {
    id: 1,
    name: 'Outside',
    prefix: '',
    iconName: 'legSide',
    views: [views[0]],
  },
  {
    id: 2,
    name: 'Front Right',
    prefix: '',
    iconName: 'legFront',
    views: [views[1]],
  },
  {
    id: 3,
    name: 'Front Left',
    prefix: '',
    iconName: 'legFront',
    views: [views[2]],
  },
  {
    id: 4,
    name: 'Inside',
    prefix: '',
    iconName: 'legSide',
    views: [views[3]],
  },
]

const previewAllTabs = [
  {
    id: 0,
    name: 'Preview All',
    prefix: 'preview',
    iconName: '',
    views: [views[0], views[1], views[2], views[3]],
    styleProps: [
      { width: '25%', height: '100%' },
      { width: '25%', height: '100%' },
      { width: '25%', height: '100%' },
      { width: '25%', height: '100%' },
    ],
    aspectRatio: {
      x: 2,
      y: 1,
    },
  },
  ...tabs,
]

const fonts = [
  {
    label: 'Script',
    value: 'BrushScript',
  },
  {
    label: 'Block',
    value: 'CenturyGothic',
  },
]

const config = {
  fonts,
  views,
  tabs,
  previewAllTabs,
}

const data = {
  config,
  outside,
  frontRight,
  frontLeft,
  inside,
}

export default data

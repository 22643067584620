import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  nikeLogo,
} from './assets'

const assets = [
  { src: asset1, x: 167.6008, y: 8.394, itemId: 106 },
  { src: asset2, x: 182.7184, y: 10.4328 },
  { src: asset3, x: 126.4828, y: 765.6064 },
  { src: asset4, x: 143.1188, y: 766.1684, itemId: 111 },
  { src: asset5, x: 140.8596, y: 0 },
  { src: asset6, x: 446.168, y: 16.9704, itemId: 105 },
  { src: asset7, x: 0, y: 20.89, itemId: 106 },
  { src: asset8, x: 10.62, y: 31.4756 },
  { src: nikeLogo, x: 584.3404, y: 226.51, itemId: 108 },
]

const data = {
  assets: assets,
}

export default data

import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  asset16,
  asset17,
  asset18,
  asset19,
  asset20,
  asset21,
  asset22,
  asset23,
  asset24,
  asset25,
  asset26,
  asset27,
  asset28,
  asset29,
  asset30,
  asset31,
  asset32,
  asset33,
  asset34,
  placeholder,
} from './assets'

const assets = [
  { src: asset1, x: -14.0596, y: 10.9544, itemId: 209 },
  { src: asset2, x: 0, y: 11.0776, itemId: 213 },
  { src: asset3, x: 435.736, y: 1170.402, itemId: 209 },
  { src: asset4, x: 174.0728, y: 326.8768, itemId: 209 },
  { src: asset5, x: 190.7808, y: 343.792, itemId: 213 },
  { src: asset6, x: 238.3076, y: 887.3144, itemId: 213 },
  { src: asset7, x: 261.3124, y: 361.4616, itemId: 201 },
  { src: asset8, x: 303.4296, y: 433.3168, globalComposition: 'multiply' },
  { src: asset9, x: 393.1084, y: 537.4188, itemId: 213 },
  { src: asset10, x: 276.946, y: 483.5044 },
  { src: asset11, x: 416.3472, y: 1199.866, itemId: 209 },
  { src: asset12, x: 546.756, y: 1244.4456, itemId: 201 },
  { src: asset13, x: 611.7944, y: 1287.0456, globalComposition: 'multiply' },
  { src: asset14, x: 564.0404, y: 1266.2152 },
  { src: asset15, x: 424.9636, y: 1200.4468, itemId: 201 },
  { src: asset16, x: 446.6024, y: 1221.8988, globalComposition: 'multiply' },
  { src: asset17, x: 429.7516, y: 1203.1372 },
  { src: asset18, x: 499.2936, y: 1331.1504 },
  { src: asset19, x: 272.0328, y: 56.4932, itemId: 213 },
  { src: asset20, x: 272.0328, y: 56.4932, itemId: 209 },
  { src: asset21, x: 71.9524, y: 109.0816 },
  { src: asset22, x: 343.4676, y: 75.5716, itemId: 201 },
  { src: asset23, x: 358.7496, y: 110.7472, globalComposition: 'multiply' },
  { src: asset24, x: 412.9204, y: 82.552 },
  { src: asset25, x: 356.9868, y: 121.6572 },
  { src: asset26, x: 341.1384, y: 97.0512 },
  { src: asset27, x: 5.37, y: 1.1008, itemId: 201 },
  { src: asset28, x: 25.466, y: 1.106, globalComposition: 'multiply' },
  { src: asset29, x: 101.3392, y: 80.7188 },
  { src: asset30, x: 236.3904, y: -1.0824, itemId: 209 },
  { src: asset31, x: 249.6444, y: 0, itemId: 213 },
  { src: asset32, x: 254.606, y: -8.93, itemId: 201 },
  { src: asset33, x: 265.7332, y: 1.1808, globalComposition: 'multiply' },
  { src: asset34, x: 301.728, y: 6.374, itemId: 213 },
  {
    src: placeholder,
    x: 320,
    y: 1200,
    itemId: 208,
  },
]

const data = {
  assets: assets,
}

export default data

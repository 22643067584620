import React, { useState, useEffect, ImgHTMLAttributes } from 'react'
import classes from './image-load.module.scss'

interface Props extends ImgHTMLAttributes<HTMLImageElement> {}

export default function ImageLoad({
  className,
  src,
  alt = '',
  ...rest
}: Props) {
  const [showImage, setShowImage] = useState(false)

  useEffect(() => {
    if (src) {
      setShowImage(false)
      const img = new Image()
      img.onload = () => setShowImage(true)
      img.src = src
    }
  }, [src])
  return (
    <img
      className={`${classes.image} ${className}`}
      src={src}
      alt={alt}
      {...rest}
      style={{
        opacity: showImage ? 1 : 0,
        pointerEvents: showImage ? 'auto' : 'none',
      }}
    />
  )
}

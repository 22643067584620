import front from './front'
import back from './back'

const views = [
  {
    id: 'front',
    name: 'Front View',
    iconName: 'catcherFront',
    productAssets: front,
    productItems: [],
    reRender: 0,
    thumbnail: true,
  },
  {
    id: 'back',
    name: 'Back View',
    iconName: 'catcherBack',
    productAssets: back,
    productItems: [],
    reRender: 0,
  },
]

const tabs = [
  {
    id: 1,
    name: 'Front View',
    prefix: '',
    iconName: 'catcherFront',
    views: [views[0]],
  },
  {
    id: 2,
    name: 'Back View',
    prefix: '',
    iconName: 'catcherBack',
    views: [views[1]],
  },
]

const previewAllTabs = [
  {
    id: 0,
    name: 'Preview All',
    prefix: 'preview',
    iconName: '',
    views: [views[0], views[1]],
    styleProps: [
      { width: '50%', height: '100%' },
      { width: '50%', height: '100%' },
    ],
    aspectRatio: {
      x: 2,
      y: 1,
    },
  },
  ...tabs,
]

const fonts = [
  {
    label: 'Script',
    value: 'BrushScript',
  },
  {
    label: 'Block',
    value: 'CenturyGothic',
  },
]

const config = {
  fonts,
  views,
  tabs,
  previewAllTabs,
}

const data = {
  config,
  front,
  back,
}

export default data

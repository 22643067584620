import React, { useRef, useEffect, useState } from 'react'
import { fabric } from 'fabric'
import FontFaceObserver from 'fontfaceobserver'
import {
  is2dLengthEqual,
  objectPath,
  loadImage,
  darkenColor,
  getLightColorPercentage,
  getProductTypeConfig,
} from 'helpers'
import { Product as ProductStyleType } from 'types'
import { Icon } from 'components'
import { useMediaQuery } from 'react-responsive'
import classes from './designer.module.scss'

const DesignerStates: any = {
  0: 'Init',
  1: 'Images Loading',
  2: 'Images Complete',
  3: 'Dimensions Loading',
  4: 'Dimensions Complete',
  5: 'Canvas Loading',
  6: 'Canvas Complete',
  7: 'Static Assets Loading',
  8: 'Static Assets Complete',
  8.1: 'Static Assets Update Complete',
  9: 'Dynamic Assets Loading',
  10: 'Dynamic Assets Complete',
  11: 'External Images Loading',
  12: 'External Images Complete',
  13: 'External Assets Loading',
  14: 'External Assets Complete',
}

type Props = {
  viewId:
    | 'palm'
    | 'top'
    | 'leftCuff'
    | 'rightCuff'
    | 'dieCast'
    | 'smallDieCast'
    | 'side'
    | 'back'
    | 'chestprotector-swoosh'
    | 'legguard-swoosh'
  viewType: string //eg: Top of Hand, Palm of Hand
  productType: ProductStyleType //eg: VJ6, SB6
  additionalOptions: any
  itemList: any[]
  customMessage: any
  reRender: number
  reset: any
  styleProps?: any
  prefix?: string
  isAdmin?: boolean
  isInteractive?: boolean
  styleName?: string
  flipX?: boolean
  onItemSelect?: (e: any) => void
  onViewUpdate?: () => void
  onUpdate?: () => void
  onComplete?: () => void
}

export default function DesignerCanvas({
  viewId,
  viewType,
  productType,
  additionalOptions,
  itemList,
  customMessage = {},
  reRender,
  reset,
  styleProps = {},
  prefix = '', // try to rename this
  isAdmin = false,
  isInteractive = false,
  styleName = '', // rename this to title
  flipX = false,
  onItemSelect,
  onViewUpdate,
  onUpdate,
  onComplete,
}: Props) {
  const canvasRef = useRef(null)
  const helperCanvasRef = useRef(null)
  const isDesktop = useMediaQuery({ minWidth: 1024 })
  const [currentState, setCurrentState] = useState(-1)
  const [canvasProps, setCanvasProps] = useState<any>({})
  const [canvasObj] = useState<any>({
    canvas: null,
    helperCanvas: null,
    helperCanvasContext: null,
    itemListById: {},
    imageAssets: {},
    loadedImages: {},
    externalImages: {},
    loadedExternalImages: {
      customPattern: {},
      logos: {},
    },
    imageColors: {},
    swoosh: {
      pattern: '',
    },
    palm: {
      material: '',
      pattern: '',
    },
    silicone: {
      pattern: '',
    },
    web: {
      pattern: '',
    },
    finger: {
      pattern: '',
    },
    customPattern: {
      material: '',
      pattern: '',
      src: '',
    },
    logos: {
      material: '',
    },
    objects: {},
    objectsJSON: {},
    activeObject: null,
    activeObjectProps: null,
    tooltip: null,
    group: null,
    flipX: false,
  })

  useEffect(() => {
    if (DesignerStates[currentState] === 'Init') {
      resetCanvasObj()
      loadImages()
    }
    if (DesignerStates[currentState] === 'Images Complete') {
      calculateCanvasDimensions()
    }
    if (DesignerStates[currentState] === 'Dimensions Complete') {
      createItemListById()
      createCanvas()
    }
    if (DesignerStates[currentState] === 'Canvas Complete') {
      renderStaticAssets()
    }
    if (DesignerStates[currentState] === 'Static Assets Complete') {
      updateStaticAssets()
    }
    if (DesignerStates[currentState] === 'Static Assets Update Complete') {
      renderDynamicAssets()
    }
    if (DesignerStates[currentState] === 'Dynamic Assets Complete') {
      loadExternalImages()
    }
    if (DesignerStates[currentState] === 'External Images Complete') {
      updateExternalAssets()
    }
    if (
      DesignerStates[currentState] === 'Dynamic Assets Complete' ||
      DesignerStates[currentState] === 'External Assets Complete'
    ) {
      canvasObj.canvas.requestRenderAll()
    }
    if (DesignerStates[currentState] === 'External Assets Complete') {
      if (onComplete) {
        onComplete()
      }
    }

    // eslint-disable-next-line
  }, [currentState])

  useEffect(() => {
    if (
      productType &&
      viewId &&
      itemList &&
      itemList.length &&
      getProductTypeConfig({ name: productType }, additionalOptions)[viewId]
    ) {
      setCurrentState(0)
    }
  }, [productType, viewId, itemList, flipX])

  useEffect(() => {
    return () => {
      if (!canvasObj.canvas) return
      const { canvas } = canvasObj
      canvas.setDimensions({ width: 10, height: 10 })
      if (isDesktop && isInteractive) {
        canvas.removeListeners()
      }
      canvas.clear()
      canvas.dispose()
      Object.keys(canvasObj).forEach((key) => {
        canvasObj[key] = null
      })
    }
  }, [])

  useEffect(() => {
    if (canvasObj.canvas) {
      if (
        flipX &&
        canvasObj.flipX &&
        (checkWeb().isChanged || checkFinger().isChanged)
      ) {
        canvasObj.imageColors = {}
        canvasObj.objects = {}
        canvasObj.web.pattern = ''
        canvasObj.finger.pattern = ''
        canvasObj.flipX = false
        canvasObj.canvas.clear()
        setCurrentState(6)
      } else {
        setCurrentState(8)
      }
    }
    // eslint-disable-next-line
  }, [reRender])

  useEffect(() => {
    if (canvasObj.canvas && reset) {
      canvasObj.imageColors = {}
      canvasObj.objects = {}
      canvasObj.swoosh.pattern = ''
      canvasObj.palm.material = ''
      canvasObj.palm.pattern = ''
      canvasObj.silicone = {}
      canvasObj.web.pattern = ''
      canvasObj.finger.pattern = ''
      canvasObj.customPattern.material = ''
      canvasObj.customPattern.pattern = ''
      canvasObj.customPattern.src = ''
      canvasObj.logos.material = ''
      canvasObj.flipX = false
      canvasObj.canvas.clear()
      setCurrentState(6)
    }
  }, [reset])

  function resetCanvasObj() {
    canvasObj.itemListById = {}
    canvasObj.imageAssets = {}
    canvasObj.loadedImages = {}
    canvasObj.externalImages = {}
    canvasObj.loadedExternalImages = {
      customPattern: {},
      logos: {},
    }
    canvasObj.imageColors = {}
    canvasObj.swoosh = {
      pattern: '',
    }
    canvasObj.palm = {
      material: '',
      pattern: '',
    }
    canvasObj.silicone = {}
    canvasObj.web = {
      pattern: '',
    }
    canvasObj.finger = {
      pattern: '',
    }
    canvasObj.customPattern = {
      material: '',
      pattern: '',
      src: '',
    }
    canvasObj.logos = {
      material: '',
    }
    canvasObj.objects = {}
    canvasObj.objectsJSON = {}
    canvasObj.activeObject = null
    canvasObj.activeObjectProps = null
    canvasObj.tooltip = null
    canvasObj.group = null
    canvasObj.flipX = false
  }

  function createCanvas() {
    setCurrentState(5)
    if (canvasObj.canvas) {
      const { canvas } = canvasObj
      const { width, height } = canvasProps

      canvas.clear()
      canvas.setDimensions({
        width,
        height,
      })

      setCurrentState(6)

      return
    }

    if (isDesktop && isInteractive) {
      canvasObj.canvas = new fabric.Canvas(canvasRef.current, {
        width: canvasProps.width,
        height: canvasProps.height,
        hoverCursor: isInteractive ? 'pointer' : 'default',
        selection: false,
      })
    } else {
      canvasObj.canvas = new fabric.StaticCanvas(canvasRef.current, {
        width: canvasProps.width,
        height: canvasProps.height,
        selection: false,
      })
    }

    canvasObj.helperCanvas = helperCanvasRef.current
    canvasObj.helperCanvasContext = canvasObj.helperCanvas.getContext('2d')

    if (isDesktop && isInteractive) {
      const { canvas, itemListById } = canvasObj

      canvas.on('mouse:over', function (e: any) {
        if (e.target) {
          let itemId = e.target.name
          if (itemId) {
            let objects = canvas.getObjects()
            objects.forEach((obj: any) => {
              if (obj.name === itemId) {
                obj.filters[0] = new fabric.Image.filters.Brightness({
                  brightness: 0.2,
                })
                obj.applyFilters()
              }
            })
            canvas.requestRenderAll()
          }
        }
      })

      canvas.on('mouse:out', function (e: any) {
        if (e.target) {
          let itemId = e.target.name
          if (itemId) {
            let objects = canvas.getObjects()
            objects.forEach((obj: any) => {
              if (obj.name === itemId) {
                obj.filters[0] = false
                obj.applyFilters()
              }
            })
            canvas.requestRenderAll()
          }
        }
      })

      canvas.on('mouse:down', function (e: any) {
        if (e.target) {
          let itemId = e.target.name
          if (itemId && onItemSelect) {
            let selectedItem = itemListById[itemId]
            onItemSelect(selectedItem)
          }
        }
      })

      canvas.on('mouse:up', function (e: any) {
        const {
          objects,
          objectsJSON,
          activeObject,
          activeObjectProps,
          tooltip,
        } = canvasObj

        if (activeObject) {
          const { left, top, angle, scaleX } = e.target
          activeObject.x = left
          activeObject.y = top
          activeObject.angle = angle
          activeObject.scale = scaleX

          if (activeObjectProps) {
            const { item, objectType, position } = activeObjectProps
            let oppositePosition = ''

            if (
              position &&
              ((objectType === 'logos' && item.symmetricalLogos) ||
                (objectType === 'customPattern' &&
                  item.symmetricalCustomPattern))
            ) {
              if (position.indexOf('Right') > -1) {
                oppositePosition = position.replace('Right', 'Left')
              } else if (position.indexOf('Left') > -1) {
                oppositePosition = position.replace('Left', 'Right')
              }

              if (
                oppositePosition &&
                objects[objectType] &&
                objects[objectType][oppositePosition]
              ) {
                const oppositeObject = objects[objectType][oppositePosition]
                const oppositeObjectJSON =
                  objectsJSON[objectType][oppositePosition]

                if (oppositeObject && oppositeObjectJSON) {
                  const { left, top, angle, scaleX } = oppositeObject
                  oppositeObjectJSON.x = left
                  oppositeObjectJSON.y = top
                  oppositeObjectJSON.angle = angle
                  oppositeObjectJSON.scale = scaleX
                }
              }
            }
          }

          if (tooltip) {
            tooltip.style.display = 'none'
          }
        }
      })

      canvas.on('object:scaling', function (e: any) {
        const { activeObjectProps, objects } = canvasObj

        if (activeObjectProps) {
          const { item, objectType, position, width, containerWidth } =
            activeObjectProps
          const { scaleX } = e.target
          let oppositePosition = ''

          if (!canvasObj.tooltip) {
            canvasObj.tooltip = document.createElement('div')
            canvasObj.tooltip.className = 'canvas-tooltip'
            document.body.appendChild(canvasObj.tooltip)
          }

          canvasObj.tooltip.innerHTML =
            ((width * scaleX * 100) / containerWidth).toFixed(2) + '%'
          canvasObj.tooltip.style.left = e.e.clientX + 10 + 'px'
          canvasObj.tooltip.style.top = e.e.clientY + 10 + 'px'
          canvasObj.tooltip.style.display = 'block'

          if (
            position &&
            ((objectType === 'logos' && item.symmetricalLogos) ||
              (objectType === 'customPattern' && item.symmetricalCustomPattern))
          ) {
            if (position.indexOf('Right') > -1) {
              oppositePosition = position.replace('Right', 'Left')
            } else if (position.indexOf('Left') > -1) {
              oppositePosition = position.replace('Left', 'Right')
            }

            if (
              oppositePosition &&
              objects[objectType] &&
              objects[objectType][oppositePosition]
            ) {
              let oppositeObject = objects[objectType][oppositePosition]

              if (objectType === 'logos') {
                oppositeObject.scale(
                  (width * scaleX) /
                    Math.max(oppositeObject.width, oppositeObject.height),
                )
              } else {
                oppositeObject.scale(scaleX)
              }
            }
          }
        }
      })

      canvas.on('object:rotating', function (e: any) {
        const { activeObjectProps, objects } = canvasObj

        if (activeObjectProps) {
          const { item, objectType, position } = activeObjectProps
          const { angle } = e.target
          let oppositePosition = ''

          if (
            position &&
            ((objectType === 'logos' && item.symmetricalLogos) ||
              (objectType === 'customPattern' && item.symmetricalCustomPattern))
          ) {
            if (position.indexOf('Right') > -1) {
              oppositePosition = position.replace('Right', 'Left')
            } else if (position.indexOf('Left') > -1) {
              oppositePosition = position.replace('Left', 'Right')
            }

            if (
              oppositePosition &&
              objects[objectType] &&
              objects[objectType][oppositePosition]
            ) {
              let oppositeObject = objects[objectType][oppositePosition]

              oppositeObject.rotate(-angle)
            }
          }
        }
      })
    }

    setCurrentState(6)
  }

  function loadImages() {
    setCurrentState(1)

    canvasObj.imageAssets = getProductTypeConfig(
      { name: productType },
      additionalOptions,
    )[viewId]

    const { imageAssets, loadedImages } = canvasObj

    Object.keys(imageAssets).forEach((key: any) => {
      if (!loadedImages[key]) {
        loadedImages[key] = []
      }

      const assets = imageAssets[key]
      const promises: any = []

      assets.forEach(function (asset: any, i: number) {
        if (!!asset.src) {
          promises.push(loadImage(asset.src))
        }
      })

      Promise.all(promises).then((values) => {
        loadedImages[key] = values
        if (is2dLengthEqual(imageAssets, loadedImages)) {
          setCurrentState(2)
        }
      })
    })
  }

  function loadExternalImages() {
    setCurrentState(11)

    const { loadedExternalImages } = canvasObj
    let listItem: any = null
    if (viewType === 'Top of Hand' || viewType === 'Palm of Hand') {
      listItem = itemList.find((item) => item.itemType === 'palm')
    } else if (viewType === 'Die Cast' || viewType === 'Small Die Cast') {
      listItem = itemList.find((item) => item.itemType === 'dieCast')
    }

    if (listItem) {
      if (listItem.customPattern) {
        listItem.customPattern.forEach((pattern: any, index: number) => {
          if (!!pattern.selected) {
            let key = index,
              imageSrc = pattern.src
            if (viewType === 'Top of Hand') {
              key = 0
              imageSrc = pattern.bohSrc
            } else if (viewType === 'Palm of Hand') {
              key = 0
            }

            if (imageSrc) {
              loadedExternalImages.customPattern[key] = null
              loadExternalImage(imageSrc).then((img) => {
                loadedExternalImages.customPattern[key] = img
                if (checkExternalImagesLength() === 1) {
                  setCurrentState(12)
                }
              })
            }
          }
        })
      }

      if (listItem.logos) {
        listItem.logos.forEach((logo: any, index: number) => {
          if (!!logo.selected) {
            loadedExternalImages.logos[index] = null
            loadExternalImage(logo.logo).then((img) => {
              loadedExternalImages.logos[index] = img

              if (checkExternalImagesLength() === 1) {
                setCurrentState(12)
              }
            })
          }
        })
      }
    }

    if (checkExternalImagesLength() === 0) {
      setCurrentState(12)
    }
  }

  function loadExternalImage(src: string) {
    const { externalImages } = canvasObj

    return new Promise((resolve: any, reject: any) => {
      if (externalImages[src]) {
        resolve(externalImages[src])
      } else {
        loadImage(src, true).then((img: any) => {
          externalImages[src] = img
          resolve(img)
        })
      }
    })
  }

  function checkExternalImagesLength() {
    const { loadedExternalImages } = canvasObj

    if (
      Object.keys(loadedExternalImages.customPattern).length === 0 &&
      Object.keys(loadedExternalImages.logos).length === 0
    ) {
      return 0
    } else if (
      Object.keys(loadedExternalImages.customPattern).length ===
        Object.values(loadedExternalImages.customPattern).filter((val) => !!val)
          .length &&
      Object.keys(loadedExternalImages.logos).length ===
        Object.values(loadedExternalImages.logos).filter((val) => !!val).length
    ) {
      return 1
    } else {
      return -1
    }
  }

  function createItemListById() {
    itemList.forEach((item) => (canvasObj.itemListById[item.itemId] = item))
  }

  function renderStaticAssets() {
    setCurrentState(7)
    const { imageAssets, objects } = canvasObj

    if (styleName) {
      renderTitle()
    }

    if (imageAssets['shadow'] && !prefix) {
      renderShadow()
    }

    renderAssets()

    if (imageAssets['3dLayer'] && !objects['3dLayer']) {
      render3dLayer()
    }
    setCurrentState(8)
  }

  function renderTitle() {
    const { canvas } = canvasObj

    new FontFaceObserver('NikeFieldTypeFootball').load(null, 5000).then(() => {
      const obj = new fabric.Text(styleName, {
        fill: '#000',
        left: canvasProps.width * 0.5,
        top: 35,
        originX: 'center',
        originY: 'center',
        fontFamily: 'NikeFieldTypeFootball',
        fontSize: 70,
        textAlign: 'center',
        selectable: false,
      })

      canvas.add(obj)
    })
  }

  function renderShadow() {
    const {
      canvas,
      imageAssets,
      loadedImages: { shadow },
    } = canvasObj

    imageAssets.shadow.forEach((asset: any, i: number) => {
      const obj = new fabric.Image(shadow[i], {
        left: asset.x + canvasProps.x,
        top: asset.y + canvasProps.y,
        width: shadow[i].width,
        height: shadow[i].height,
        globalCompositeOperation: asset.globalComposition || 'source-over',
        selectable: false,
        evented: false,
      })

      canvas.add(obj)
    })
  }

  function render3dLayer() {
    const {
      canvas,
      imageAssets,
      loadedImages: { '3dLayer': layer3d },
      objects,
    } = canvasObj

    if (!objects['3dLayer']) {
      objects['3dLayer'] = {}
    }

    imageAssets['3dLayer'].forEach(function (asset: any, i: number) {
      if (objects['3dLayer'][i]) {
        canvas.remove(objects['3dLayer'][i])
        objects['3dLayer'][i] = null
      }

      const obj = new fabric.Image(layer3d[i], {
        left: asset.x + canvasProps.x,
        top: asset.y + canvasProps.y,
        width: layer3d[i].width,
        height: layer3d[i].height,
        globalCompositeOperation: asset.globalComposition || 'source-over',
        selectable: false,
        evented: false,
      })

      canvas.add(obj)
      objects['3dLayer'][i] = obj
    })
  }

  function renderAssets() {
    const {
      canvas,
      imageAssets,
      loadedImages: { assets },
      objects,
    } = canvasObj

    if (!objects.assets) {
      objects.assets = {}
    }
    const swoosh = itemList.find((item) => item.itemType === 'swoosh')

    imageAssets.assets.forEach((asset: any, i: number) => {
      if (
        !!asset.src &&
        !asset.overlay &&
        (!asset.brand || asset.brand === swoosh.brand)
      ) {
        const obj: any = new fabric.Image(assets[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: assets[i].width,
          height: assets[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })

        if (asset.defaultColor) {
          applyColor({
            obj,
            img: assets[i],
            color: asset.defaultColor,
            blendMode: asset.blendMode,
          })
        }

        canvas.add(obj)
        objects.assets[i] = obj
      }
    })
  }

  function renderOverlayAssets() {
    const {
      canvas,
      imageAssets,
      loadedImages: { assets },
      objects,
    } = canvasObj

    if (!objects.assets) {
      objects.assets = {}
    }

    imageAssets.assets.forEach(function (asset: any, i: number) {
      if (asset.overlay) {
        if (objects.assets[i]) {
          canvas.remove(objects.assets[i])
          objects.assets[i] = null
        }

        const obj = new fabric.Image(assets[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: assets[i].width,
          height: assets[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })

        canvas.add(obj)
        objects.assets[i] = obj
      }
    })
  }

  function updateStaticAssets() {
    const { imageAssets, objects } = canvasObj

    const swooshOutlineItems = itemList.filter(
      (item: any) => item.itemType === 'swooshOutline',
    )
    if (swooshOutlineItems.length) {
      const itemIds = swooshOutlineItems.map((item: any) => item.itemId)
      imageAssets.assets.forEach((asset: any, i: number) => {
        const itemIndex = itemIds.indexOf(asset.itemId)
        if (itemIndex > -1) {
          objects.assets[i].visible =
            !!swooshOutlineItems[itemIndex].enableSwooshOutline
        }
      })
    }

    setCurrentState(8.1)
  }

  function renderDynamicAssets() {
    setCurrentState(9)
    const { canvas, imageAssets, objects } = canvasObj
    let hasChangedAssets = false

    const swooshData: any = checkSwoosh()
    if (swooshData.isChanged) {
      renderSwoosh(swooshData.pattern)
      hasChangedAssets = true
    }

    const webData: any = checkWeb()
    if (webData.isChanged) {
      renderWeb(webData.pattern)
      hasChangedAssets = true
    }

    const palmData: any = checkPalm()
    if (palmData.isChanged) {
      renderPalm(palmData.material, palmData.pattern)
      hasChangedAssets = true
    }

    const fingerData: any = checkFinger()
    if (fingerData.isChanged) {
      renderFinger(fingerData.pattern)
      hasChangedAssets = true
    }

    if (imageAssets['3dLayer'] && !objects['3dLayer']) {
      render3dLayer()
      hasChangedAssets = true
    }

    if (hasChangedAssets) {
      renderOverlayAssets()
    }

    if (flipX && !canvasObj.flipX) {
      canvasObj.group = new fabric.Group(canvas.getObjects(), {
        flipX: true,
      })
      canvasObj.flipX = true

      if (webData.isChanged) {
        renderFlippedWeb(webData.pattern)
      }
    }

    if (imageAssets.engraving) {
      renderEngraving()
    }

    updateAssetColors()

    const siliconeData: any = checkSilicone()
    if (siliconeData.isChanged) {
      renderSilicone(siliconeData.pattern)
    }

    renderMaterial()

    renderArtwork()

    if (viewType === 'Left Inside Cuff' || viewType === 'Right Inside Cuff') {
      renderCustomMessage()
    }
    if (viewType === 'Front View') {
      renderNeckRollEmb()
    }
    if (viewType === 'Front Right' || viewType === 'Outside') {
      renderPlayerInfo()
    }
    setCurrentState(10)
  }

  function renderSwoosh(pattern: string) {
    const { canvas, imageAssets, loadedImages, objects, swoosh, imageColors } =
      canvasObj
    const previousPattern = swoosh.pattern
    const swooshImages = imageAssets[pattern]
    const loadedSwooshImages = loadedImages[pattern]

    if (!objects[pattern]) {
      objects[pattern] = {}
    }

    if (objects[previousPattern]) {
      Object.values(objects[previousPattern]).forEach((obj: any, i: number) => {
        canvas.remove(obj)
      })
      objects[previousPattern] = {}
      imageColors[previousPattern] = {}
    }
    swoosh.pattern = pattern

    if (swooshImages) {
      swooshImages.forEach(function (asset: any, i: number) {
        const obj = new fabric.Image(loadedSwooshImages[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: loadedSwooshImages[i].width,
          height: loadedSwooshImages[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })
        canvas.add(obj)
        objects[pattern][i] = obj
      })
    }
  }

  function renderWeb(pattern: string) {
    const { canvas, imageAssets, loadedImages, objects, web, imageColors } =
      canvasObj
    const previousPattern = web.pattern
    const webImages = imageAssets[pattern]
    const loadedWebImages = loadedImages[pattern]

    if (!objects[pattern]) {
      objects[pattern] = {}
    }

    if (objects[previousPattern]) {
      Object.values(objects[previousPattern]).forEach((obj: any, i: number) => {
        canvas.remove(obj)
      })
      objects[previousPattern] = {}
      imageColors[previousPattern] = {}
    }
    web.pattern = pattern

    if (webImages) {
      webImages.forEach(function (asset: any, i: number) {
        const obj = new fabric.Image(loadedWebImages[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: loadedWebImages[i].width,
          height: loadedWebImages[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })
        if (asset.angle) {
          obj.rotate(asset.angle)
        }

        canvas.add(obj)
        objects[pattern][i] = obj
      })
    }
  }

  function renderFlippedWeb(pattern: string) {
    if (pattern !== 'full-k') return

    const { canvas, imageAssets, loadedImages, objects, web, imageColors } =
      canvasObj
    const previousPattern = web.pattern
    const newPattern = pattern + '-flip'
    const webImages = imageAssets[newPattern]
    const loadedWebImages = loadedImages[newPattern]

    if (!webImages) return

    if (!objects[newPattern]) {
      objects[newPattern] = {}
    }

    if (objects[previousPattern]) {
      Object.values(objects[previousPattern]).forEach((obj: any, i: number) => {
        canvas.remove(obj)
      })
      objects[previousPattern] = {}
      imageColors[previousPattern] = {}
    }
    web.pattern = newPattern

    if (webImages) {
      webImages.forEach(function (asset: any, i: number) {
        const obj = new fabric.Image(loadedWebImages[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: loadedWebImages[i].width,
          height: loadedWebImages[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })
        if (asset.angle) {
          obj.rotate(asset.angle)
        }

        canvas.add(obj)
        objects[newPattern][i] = obj
      })
    }
  }

  function renderPalm(material: string, pattern: string) {
    const { canvas, imageAssets, loadedImages, objects, palm, imageColors } =
      canvasObj
    const previousPattern = palm.pattern
    const palmImages = imageAssets[pattern]
    const loadedPalmImages = loadedImages[pattern]

    if (!objects[pattern]) {
      objects[pattern] = {}
    }

    if (objects[previousPattern]) {
      Object.values(objects[previousPattern]).forEach((obj: any, i: number) => {
        canvas.remove(obj)
      })
      objects[previousPattern] = {}
      imageColors[previousPattern] = {}
    }
    palm.material = material
    palm.pattern = pattern

    if (
      palmImages &&
      (material === 'Magnagrip Silicone' || material === 'Magnigrip+') &&
      pattern !== 'solid'
    ) {
      palmImages.forEach(function (asset: any, i: number) {
        const obj = new fabric.Image(loadedPalmImages[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: loadedPalmImages[i].width,
          height: loadedPalmImages[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: false,
        })

        canvas.add(obj)
        objects[pattern][i] = obj
      })
    }
  }

  function renderSilicone(pattern: string) {
    const {
      imageAssets: { assets },
      imageColors,
      objects,
      loadedImages,
    } = canvasObj

    const siliconeItem = itemList.find(
      (item: any) => item.itemType === 'silicone',
    )

    if (siliconeItem) {
      assets.forEach((asset: any, i: number) => {
        if (asset.itemId === siliconeItem.itemId) {
          const siliconeObject = objects.assets[i]

          const parentAsset = assets.find(
            (asset2: any) => asset2.itemId === asset.parentId,
          )

          const selectedOption = getSelectedColorForAsset(asset)
          const parentSelectedOption = getSelectedColorForAsset(parentAsset)
          if (
            pattern === 'Clear' ||
            pattern === 'Solid' ||
            (selectedOption &&
              parentSelectedOption &&
              selectedOption.code === parentSelectedOption.code)
          ) {
            if (parentSelectedOption && parentSelectedOption.code) {
              let alpha = 1
              if (pattern === 'Solid') {
                if (parentSelectedOption.code.toLowerCase() === '#ffffff') {
                  alpha = 0.95
                } else {
                  if (productType === 'VJ8') {
                    alpha = 0.8
                  } else {
                    alpha = 0.7
                  }
                }
              } else {
                if (parentSelectedOption.code.toLowerCase() === '#ffffff') {
                  alpha = 0.9
                } else {
                  if (productType === 'VJ8') {
                    alpha = 0.75
                  } else {
                    alpha = 0.5
                  }
                }
              }

              applyColor({
                obj: siliconeObject,
                img: loadedImages.assets[i],
                color: parentSelectedOption.code,
                alpha,
              })
            }
          } else {
            applyColor({
              obj: siliconeObject,
              img: loadedImages.assets[i],
              color: selectedOption ? selectedOption.code : '#FFFFFF',
            })
          }

          imageColors['assets'][i] = ''
        }
      })
    }
  }

  function renderFinger(pattern: string) {
    const { canvas, imageAssets, loadedImages, objects, finger, imageColors } =
      canvasObj
    const previousPattern = finger.pattern
    const fingerImages = imageAssets[pattern]
    const loadedFingerImages = loadedImages[pattern]

    if (!objects[pattern]) {
      objects[pattern] = {}
    }

    if (objects[previousPattern]) {
      Object.values(objects[previousPattern]).forEach((obj: any, i: number) => {
        canvas.remove(obj)
      })
      objects[previousPattern] = {}
      imageColors[previousPattern] = {}
    }
    finger.pattern = pattern

    if (fingerImages) {
      fingerImages.forEach(function (asset: any, i: number) {
        const obj = new fabric.Image(loadedFingerImages[i], {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
          width: loadedFingerImages[i].width,
          height: loadedFingerImages[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
        })
        if (asset.angle) {
          obj.rotate(asset.angle)
        }

        canvas.add(obj)
        if (asset.zindex) {
          obj.moveTo(asset.zindex)
        }
        objects[pattern][i] = obj
      })
    }
  }

  function renderMaterial() {
    if (productType.indexOf('Fielding Glove') === -1) return

    const {
      canvas,
      imageAssets,
      loadedImages,
      objects,
      silicone,
      imageColors,
    } = canvasObj
    const previousPattern = silicone.pattern

    if (objects[previousPattern]) {
      Object.values(objects[previousPattern]).forEach((obj: any, i: number) => {
        canvas.remove(obj)
      })
      objects[previousPattern] = {}
      imageColors[previousPattern] = {}
    }

    const selectedOption = getSelectedColorForAsset({ itemId: 101 })

    if (!selectedOption) return
    if (!selectedOption.material) return

    const pattern = selectedOption.material
      .toLowerCase()
      .replace('/', '')
      .replace(/\s/g, '-')

    silicone.pattern = pattern

    const siliconeImages = imageAssets[pattern]
    const loadedSiliconeImages = loadedImages[pattern]

    if (!objects[pattern]) {
      objects[pattern] = {}
    }

    if (siliconeImages) {
      siliconeImages.forEach(function (asset: any, i: number) {
        let positionObj = {
          left: asset.x + canvasProps.x,
          top: asset.y + canvasProps.y,
        }
        if (flipX && canvasObj.flipX) {
          positionObj.left =
            canvasProps.width - positionObj.left - loadedSiliconeImages[i].width
        }
        const obj = new fabric.Image(loadedSiliconeImages[i], {
          ...positionObj,
          width: loadedSiliconeImages[i].width,
          height: loadedSiliconeImages[i].height,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: false,
          evented: !!asset.itemId,
          perPixelTargetFind: true,
          name: asset.itemId,
          flipX: flipX && canvasObj.flipX,
        })

        canvas.add(obj)
        if (asset.zindex) {
          obj.moveTo(asset.zindex)
        }
        objects[pattern][i] = obj
      })
    }
  }

  function renderPalmCustomPattern(
    material: string,
    pattern: string,
    src: string,
  ) {
    const {
      canvas,
      imageAssets,
      loadedExternalImages,
      objects,
      customPattern,
    } = canvasObj

    if (objects.customPattern) {
      canvas.remove(objects.customPattern)
      objects.customPattern = null
    }
    customPattern.material = material
    customPattern.pattern = pattern
    customPattern.src = src

    if (
      (material === 'Magnagrip Silicone' || material === 'Magnigrip+') &&
      pattern === 'solid' &&
      src
    ) {
      const loadedCustomPattern = loadedExternalImages.customPattern[0]
      let placementCorrection: any = {},
        scaleCorrection: any = {},
        zIndex: any = {}

      if (viewType === 'Top of Hand') {
        placementCorrection = {
          SB6: 600,
          SB7: 56,
          VJ6: 225,
          VJ7: 225,
          VJ8: 60,
          VK4: 240,
        }
        scaleCorrection = {
          SB6: -80,
          SB7: -95,
        }
        zIndex = {
          SB6: 11,
          SB7: 5,
          VJ6: 4,
          VJ7: 4,
          VJ8: 7,
          VK4: 5,
        }
      } else if (viewType === 'Palm of Hand') {
        scaleCorrection = {
          VK4: 10,
        }
      }

      const obj = new fabric.Image(loadedCustomPattern, {
        left: canvasProps.x,
        top: canvasProps.y + placementCorrection[productType] || 0,
        width: loadedCustomPattern.width,
        height: loadedCustomPattern.height,
        globalCompositeOperation: 'source-over',
        selectable: false,
        evented: false,
      })
      obj.scale(
        (canvas.width -
          canvasProps.x * 2 +
          (scaleCorrection[productType] || 0)) /
          loadedCustomPattern.width,
      )

      canvas.add(obj)
      if (zIndex[productType]) {
        if (imageAssets['shadow'] && !prefix) {
          obj.moveTo(zIndex[productType] + 1)
        } else {
          obj.moveTo(zIndex[productType])
        }
      }
      objects.customPattern = obj
    }
  }

  function renderPalmLogos() {
    const { canvas, objects, objectsJSON } = canvasObj
    const palmItem = itemList.find((item) => item.itemType === 'palm')

    if (objects.logos) {
      Object.values(objects.logos).forEach((obj: any) => {
        canvas.remove(obj)
      })
    }
    objects.logos = {}
    objectsJSON.logos = {}

    if (
      palmItem &&
      (palmItem.material === 'Magnagrip Silicone' ||
        palmItem.material === 'Magnigrip+')
    ) {
      palmItem.logos.forEach((logo: any, i: number) => {
        if (!!logo.selected && logo.position) {
          logo.position.forEach((position: any, positionIndex: number) =>
            drawPalmLogo(palmItem, logo, i, positionIndex),
          )
        }
      })
    }
  }

  function drawPalmLogo(
    palmItem: any,
    logo: any,
    logoIndex: number,
    positionIndex: number,
  ) {
    const { canvas, loadedExternalImages, objects, objectsJSON } = canvasObj
    let position = logo.position[positionIndex]
    let path = ['config', 'logoPositions']
    let defaultPosition = {}
    let containerSize = 80

    switch (position.name) {
      case 'Right Small Cuff':
        path.push('Small Cuff', 'right')
        defaultPosition = {
          x: 140,
          y: 890,
          angle: 50,
        }
        containerSize = 80
        break
      case 'Left Small Cuff':
        path.push('Small Cuff', 'left')
        defaultPosition = {
          x: 1295,
          y: 900,
          angle: -45,
        }
        containerSize = 80
        break
      case 'Right Middle Palm':
        path.push('Middle Palm', 'right')
        defaultPosition = {
          x: 400,
          y: 675,
          angle: 45,
        }
        containerSize = 325
        break
      case 'Left Middle Palm':
        path.push('Middle Palm', 'left')
        defaultPosition = {
          x: 1060,
          y: 680,
          angle: -45,
        }
        containerSize = 325
        break
      case 'LockUp':
        path.push('LockUp')
        defaultPosition = {
          x: 725,
          y: 600,
        }
        containerSize = 700
        break
    }

    let maxImageSize = Math.max(
      loadedExternalImages.logos[logoIndex].width,
      loadedExternalImages.logos[logoIndex].height,
    )
    if (
      position.name === 'Right Small Cuff' ||
      position.name === 'Left Small Cuff'
    ) {
      maxImageSize = loadedExternalImages.logos[logoIndex].height
    }
    const ratio = containerSize / maxImageSize

    //set default values
    //when the logo is placed for the first time
    if (!position.x) {
      const {
        x,
        y,
        angle = 0,
      } = objectPath(
        getProductTypeConfig({ name: productType }, additionalOptions),
        path,
        defaultPosition,
      )

      position = {
        ...position,
        ...{ x: x + canvasProps.x, y: y + canvasProps.y, angle: angle },
        scale: ratio,
      }
      logo.position[positionIndex] = position
    }

    const obj: any = new fabric.Image(loadedExternalImages.logos[logoIndex], {
      left: position.x,
      top: position.y,
      globalCompositeOperation: 'source-over',
      originX: 'center',
      originY: 'center',
      angle: position.angle,
      borderColor: '#FD567F',
      borderDashArray: [16, 12],
      borderScaleFactor: 3,
      borderOpacityWhenMoving: 0.8,
      padding: 10,
      cornerStyle: 'circle',
      cornerSize: 24,
      cornerColor: '#FD567F',
      cornerStrokeColor: 'white',
      selectable: isAdmin && !prefix,
      evented: isAdmin && !prefix,
      perPixelTargetFind: true,
    })
    obj.scale(position.scale)

    obj.onSelect = function () {
      canvasObj.activeObject = logo.position[positionIndex]
      canvasObj.activeObjectProps = {
        item: palmItem,
        objectType: 'logos',
        position: position.name,
        width: maxImageSize,
        containerWidth: containerSize,
      }
    }
    obj.onDeselect = function () {
      canvasObj.activeObject = null
      canvasObj.activeObjectProps = null
      if (canvasObj.tooltip) {
        document.body.removeChild(canvasObj.tooltip)
        canvasObj.tooltip = null
      }

      if (onViewUpdate) onViewUpdate()
    }

    // prevent resizing on sides
    obj.setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false,
    })

    canvas.add(obj)
    objects.logos[position.name] = obj
    objectsJSON.logos[position.name] = logo.position[positionIndex]
  }

  function renderCustomMessage() {
    const { canvas, objects } = canvasObj

    if (objects.customMessage) {
      Object.values(objects.customMessage).forEach((obj) => {
        canvas.remove(obj)
      })
    }
    objects.customMessage = {}

    if (customMessage.type === 'text') {
      let fontSize = 180
      if (customMessage.value) {
        if (customMessage.value.length > 16) {
          fontSize = 90
        } else if (customMessage.value.length > 12) {
          fontSize = 120
        } else if (customMessage.value.length > 8) {
          fontSize = 150
        }
      }
      new FontFaceObserver('NikeFieldTypeFootball')
        .load(null, 5000)
        .then(() => {
          const obj = new fabric.Text(customMessage.value || '', {
            fill: customMessage.color || '#000',
            left: 640 + canvasProps.x,
            top: 320 + canvasProps.y,
            originX: 'center',
            originY: 'center',
            fontFamily: 'NikeFieldTypeFootball',
            fontSize,
            textAlign: 'center',
            selectable: false,
            evented: false,
          })

          canvas.add(obj)
          objects.customMessage.text = obj
        })
    } else if (customMessage.type === 'logo') {
      loadExternalImage(customMessage.value).then((img: any) => {
        const widthRatio = 960 / img.width
        const heightRatio = 320 / img.height
        const ratio = Math.min(widthRatio, heightRatio)

        const obj = new fabric.Image(img, {
          left: 645 + canvasProps.x,
          top: 250 + canvasProps.y,
          originX: 'center',
          originY: 'center',
          globalCompositeOperation: 'source-over',
          selectable: false,
          evented: false,
        })
        obj.scale(ratio)

        canvas.add(obj)
        objects.customMessage.logo = obj
      })
    }
  }

  function renderDieCastCustomPattern() {
    const { canvas, objects, objectsJSON } = canvasObj
    const dieCastItem = itemList.find((item) => item.itemType === 'dieCast')

    if (objects.customPattern) {
      Object.values(objects.customPattern).forEach((obj: any) => {
        canvas.remove(obj)
      })
    }
    objects.customPattern = {}
    objectsJSON.customPattern = {}

    if (dieCastItem) {
      dieCastItem.customPattern.forEach((pattern: any, i: any) => {
        if (viewType === 'Small Die Cast') {
          if (
            !!pattern.selected &&
            pattern.position &&
            pattern.size === 'small'
          ) {
            pattern.position.forEach((position: any, positionIndex: number) => {
              renderDieCastPattern(dieCastItem, pattern, i, positionIndex)
            })
          }
        } else {
          if (
            !!pattern.selected &&
            pattern.position &&
            pattern.size !== 'small'
          ) {
            pattern.position.forEach((position: any, positionIndex: number) => {
              renderDieCastPattern(dieCastItem, pattern, i, positionIndex)
            })
          }
        }
      })
    }
  }

  function renderDieCastPattern(
    dieCastItem: any,
    pattern: any,
    patternIndex: number,
    positionIndex: number,
  ) {
    const {
      canvas,
      imageAssets,
      loadedImages: { assets },
      loadedExternalImages,
      objects,
      objectsJSON,
    } = canvasObj
    let position = pattern.position[positionIndex]
    let defaultValues = { x: 0, y: 0 }
    let containerSize = 751

    switch (position.name) {
      case 'Right':
        const rightIndex = imageAssets.assets.findIndex(
          (asset: any) =>
            asset.itemId === 'dieCast' && asset.position === 'Right',
        )
        defaultValues = {
          x: assets[rightIndex].width * 0.5,
          y: assets[rightIndex].height * 0.5,
        }
        containerSize = 756
        break
      case 'Left':
        const leftIndex = imageAssets.assets.findIndex(
          (asset: any) =>
            asset.itemId === 'dieCast' && asset.position === 'Left',
        )
        defaultValues = {
          x: imageAssets.assets[leftIndex].x + assets[leftIndex].width * 0.5,
          y: imageAssets.assets[leftIndex].y + assets[leftIndex].height * 0.5,
        }
        containerSize = 770
        break
    }
    const maxImageSize = Math.max(
      loadedExternalImages.customPattern[patternIndex].width,
      loadedExternalImages.customPattern[patternIndex].height,
    )
    const ratio = containerSize / maxImageSize
    if (!position.x) {
      position = {
        ...position,
        ...{
          x: defaultValues.x + canvasProps.x,
          y: defaultValues.y + canvasProps.y,
          angle: 0,
        },
        scale: ratio,
      }
      pattern.position[positionIndex] = position
    }
    const obj: any = new fabric.Image(
      loadedExternalImages.customPattern[patternIndex],
      {
        left: position.x,
        top: position.y,
        angle: position.angle,
        originX: 'center',
        originY: 'center',
        centeredScaling: true,
        globalCompositeOperation: 'source-atop',
        selectable: isAdmin && !prefix,
        borderColor: '#FD567F',
        borderDashArray: [16, 12],
        borderScaleFactor: 3,
        borderOpacityWhenMoving: 0.8,
        cornerStyle: 'circle',
        cornerSize: 15,
        cornerColor: '#FD567F',
        cornerStrokeColor: 'white',
      },
    )
    obj.scale(position.scale)
    obj.onSelect = function () {
      canvasObj.activeObject = pattern.position[positionIndex]
      canvasObj.activeObjectProps = {
        item: dieCastItem,
        objectType: 'customPattern',
        position: position.name,
        width: maxImageSize,
        containerWidth: containerSize,
      }
    }
    obj.onDeselect = function () {
      canvasObj.activeObject = null
      canvasObj.activeObjectProps = null
      if (canvasObj.tooltip) {
        document.body.removeChild(canvasObj.tooltip)
        canvasObj.tooltip = null
      }

      if (onViewUpdate) onViewUpdate()
    }

    // prevent resizing on sides
    obj.setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false,
    })

    canvas.add(obj)
    objects.customPattern[position.name] = obj
    objectsJSON.customPattern[position.name] = pattern.position[positionIndex]
  }

  function renderDieCastLogos() {
    const { canvas, objects, objectsJSON } = canvasObj
    const dieCastItem = itemList.find((item) => item.itemType === 'dieCast')

    if (objects.logos) {
      Object.values(objects.logos).forEach((obj: any) => {
        canvas.remove(obj)
      })
    }
    objects.logos = {}
    objectsJSON.logos = {}

    if (dieCastItem) {
      dieCastItem.logos.forEach((logo: any, i: number) => {
        if (!!logo.selected && logo.position) {
          logo.position.forEach((position: any, positionIndex: number) =>
            drawDieCastLogo(dieCastItem, logo, i, positionIndex),
          )
        }
      })
    }
  }

  function drawDieCastLogo(
    dieCastItem: any,
    logo: any,
    logoIndex: number,
    positionIndex: number,
  ) {
    const { canvas, loadedExternalImages, objects, objectsJSON } = canvasObj
    let position = logo.position[positionIndex]
    let path = ['config', 'dieCastLogoPositions']
    let defaultPosition = {}
    let containerSize = 80

    switch (position.name) {
      case 'Right Small Cuff':
        path.push('Small Cuff', 'right')
        defaultPosition = {
          x: 122,
          y: 922,
          angle: 45,
        }
        containerSize = 60
        break
      case 'Left Small Cuff':
        path.push('Small Cuff', 'left')
        defaultPosition = {
          x: 1258,
          y: 928,
          angle: -45,
        }
        containerSize = 60
        break
      case 'Right Middle Palm':
        path.push('Middle Palm', 'right')
        defaultPosition = {
          x: 400,
          y: 675,
          angle: 45,
        }
        containerSize = 110
        break
      case 'Left Middle Palm':
        path.push('Middle Palm', 'left')
        defaultPosition = {
          x: 978,
          y: 686,
          angle: -45,
        }
        containerSize = 110
        break
    }

    const maxImageSize = Math.max(
      loadedExternalImages.logos[logoIndex].width,
      loadedExternalImages.logos[logoIndex].height,
    )
    const ratio = containerSize / maxImageSize

    if (!position.x) {
      const {
        x,
        y,
        angle = 0,
      } = objectPath(
        getProductTypeConfig({ name: productType }, additionalOptions),
        path,
        defaultPosition,
      )

      position = {
        ...position,
        ...{ x: x + canvasProps.x, y: y + canvasProps.y, angle: angle },
        scale: ratio,
      }
      logo.position[positionIndex] = position
    }

    const obj: any = new fabric.Image(loadedExternalImages.logos[logoIndex], {
      left: position.x,
      top: position.y,
      globalCompositeOperation: 'source-over',
      originX: 'center',
      originY: 'center',
      centeredScaling: true,
      angle: position.angle,
      selectable: isAdmin && !prefix,
      borderColor: '#FD567F',
      borderDashArray: [16, 12],
      borderScaleFactor: 3,
      borderOpacityWhenMoving: 0.8,
      padding: 10,
      cornerStyle: 'circle',
      cornerSize: 24,
      cornerColor: '#FD567F',
      cornerStrokeColor: 'white',
    })
    obj.scale(position.scale)
    obj.onSelect = function () {
      canvasObj.activeObject = logo.position[positionIndex]
      canvasObj.activeObjectProps = {
        item: dieCastItem,
        objectType: 'logos',
        position: position.name,
        width: maxImageSize,
        containerWidth: containerSize,
      }
    }
    obj.onDeselect = function () {
      canvasObj.activeObject = null
      canvasObj.activeObjectProps = null
      if (canvasObj.tooltip) {
        document.body.removeChild(canvasObj.tooltip)
        canvasObj.tooltip = null
      }

      if (onViewUpdate) onViewUpdate()
    }

    // prevent resizing on sides
    obj.setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false,
    })

    canvas.add(obj)
    objects.logos[position.name] = obj
    objectsJSON.logos[position.name] = logo.position[positionIndex]
  }

  function renderNeckRollEmb() {
    const { canvas, objects, imageAssets } = canvasObj

    if (objects.artwork) {
      Object.values(objects.artwork).forEach((obj) => {
        canvas.remove(obj)
      })
    }
    objects.artwork = {}

    const embroideryItem = itemList.find(
      (item) => item.itemType === 'neckrollembroidery' && item.showEmbroidery,
    )

    if (!embroideryItem) return

    let selectedPatternIndex = -1

    if (embroideryItem.customPattern) {
      selectedPatternIndex = embroideryItem.customPattern.findIndex(
        (pattern: any) => !!pattern.selected,
      )
    }
    if (
      selectedPatternIndex > -1 &&
      embroideryItem.customPattern[selectedPatternIndex].src
    ) {
      renderEmbArtwork(embroideryItem)
    } else {
      let fontSize = 40
      if (embroideryItem.value && embroideryItem.value.length > 6) {
        fontSize = 26
      }
      const artworkItem = imageAssets.assets.find(
        (asset: any) => asset.itemId === embroideryItem.itemId,
      )
      if (artworkItem) {
        const selectedOption = getSelectedColorForAsset(artworkItem)

        let gradient: any = null
        if (selectedOption && selectedOption.multiColors) {
          gradient = getGradientColor(selectedOption.multiColors)
        }

        const positionObj = {
          left: artworkItem.x + canvasProps.x,
          top: artworkItem.y + canvasProps.y,
          angle: artworkItem.angle,
        }

        let textOptions: any = {
          ...positionObj,
          fill:
            gradient ||
            darkenColor(
              (selectedOption && selectedOption.code) || '#000000',
              50,
            ),
          originX: 'center',
          originY: 'center',
          fontFamily: 'Swiss',
          fontSize,
          textAlign: 'center',
          selectable: false,
          evented: false,
        }

        if (embroideryItem.stroke) {
          const selectedStroke = embroideryItem.strokeColor.find(
            (option: any) => !!option.selected,
          )
          let strokeGradient: any = null
          if (selectedStroke && selectedStroke.multiColors) {
            strokeGradient = getGradientColor(selectedStroke.multiColors)
          }
          textOptions = {
            ...textOptions,
            stroke: strokeGradient || (selectedStroke && selectedStroke.code),
            strokeWidth: 1.2,
          }
        }

        Promise.all([new FontFaceObserver('Swiss').load(null, 5000)]).then(
          () => {
            const obj = new fabric.Text(embroideryItem.value || '', textOptions)

            canvas.add(obj)
            objects.artwork[embroideryItem.itemId] = obj
          },
        )
      }
    }
  }

  function renderArtwork() {
    const { canvas, objects } = canvasObj
    if (objects.artwork) {
      Object.values(objects.artwork).forEach((obj) => {
        canvas.remove(obj)
      })
    }

    if (objects.embArtwork) {
      Object.values(objects.embArtwork).forEach((obj: any) => {
        canvas.remove(obj)
      })
    }
    objects.artwork = {}
    objects.embArtwork = {}

    itemList.forEach((item: any) => {
      if (item.itemType !== 'embroidery') return

      if (
        item.item === 'Thumb Embroidery' ||
        item.item === 'Pinky Embroidery'
      ) {
        let selectedPatternIndex = -1

        if (item.customPattern) {
          selectedPatternIndex = item.customPattern.findIndex(
            (pattern: any) => !!pattern.selected,
          )
        }
        if (
          selectedPatternIndex > -1 &&
          item.customPattern[selectedPatternIndex].src
        ) {
          renderEmbArtwork(item)
        } else {
          drawArtWork(item)
        }
      }
    })
  }

  function renderEmbArtwork(item: any) {
    item.customPattern.forEach((pattern: any, i: number) => {
      if (pattern.selected) {
        loadExternalImage(item.customPattern[i].src).then((img: any) => {
          drawEmbArtWork(item, i, img)
        })
      }
    })
  }

  function drawEmbArtWork(embItem: any, patternIndex: number, img: any) {
    const { canvas, objects, imageAssets } = canvasObj
    const embImg = img

    imageAssets.assets.forEach((asset: any) => {
      if (asset.itemId === embItem.itemId) {
        let positionObj: any = {
          x: asset.x + canvasProps.x,
          y: asset.y + canvasProps.y,
          angle: asset.angle,
        }
        let container: any = { width: 50, height: 50 }

        switch (viewType) {
          case 'Side of Hand':
            container = {
              width: 150,
              height: 100,
            }
            break
          case 'Top of Hand':
            if (embItem.item === 'Thumb Embroidery') {
              container = {
                width: 45,
                height: 30,
              }
            } else {
              container = {
                width: 90,
                height: 200,
              }
            }
            break
          case 'Back of Hand':
            container = {
              width: 200,
              height: 50,
            }
            break
          case 'Front View':
            container = {
              width: 150,
              height: 58,
            }
            positionObj.y = positionObj.y + 12
            break
        }

        if (flipX && canvasObj.flipX) {
          positionObj.x = canvasProps.width - positionObj.x
          positionObj.angle = -positionObj.angle
        }

        const widthRatio = container.width / embImg.width
        const heightRatio = container.height / embImg.height
        const ratio = Math.min(widthRatio, heightRatio)

        positionObj.scale = ratio
        if (embItem && patternIndex > -1) {
          if (
            embItem.customPattern[patternIndex].position &&
            embItem.customPattern[patternIndex].position[viewType]
          ) {
            positionObj = embItem.customPattern[patternIndex].position[viewType]
          } else {
            if (!embItem.customPattern[patternIndex].position) {
              embItem.customPattern[patternIndex].position = {}
            }
            embItem.customPattern[patternIndex].position[viewType] = positionObj
          }
        }

        const obj: any = new fabric.Image(embImg, {
          left: positionObj.x,
          top: positionObj.y,
          originX: 'center',
          originY: 'center',
          centeredScaling: true,
          angle: positionObj.angle,
          globalCompositeOperation: asset.globalComposition || 'source-over',
          selectable: isAdmin && !prefix,
          borderColor: '#FD567F',
          borderDashArray: [16, 12],
          borderScaleFactor: 3,
          borderOpacityWhenMoving: 0.8,
          padding: 10,
          cornerStyle: 'circle',
          cornerSize: 24,
          cornerColor: '#FD567F',
          cornerStrokeColor: 'white',
        })
        obj.scale(positionObj.scale)

        obj.onSelect = function () {
          canvasObj.activeObject =
            embItem.customPattern[patternIndex].position[viewType]
        }
        obj.onDeselect = function () {
          canvasObj.activeObject = null
          if (canvasObj.tooltip) {
            document.body.removeChild(canvasObj.tooltip)
            canvasObj.tooltip = null
          }

          if (onViewUpdate) onViewUpdate()
        }

        // prevent resizing on sides
        obj.setControlsVisibility({
          mb: false,
          ml: false,
          mr: false,
          mt: false,
        })

        canvas.add(obj)
        objects.embArtwork[asset.itemId] = obj
      }
    })
  }

  function drawArtWork(item: any) {
    const { canvas, imageAssets, objects } = canvasObj
    const artworkItem = imageAssets.assets.find(
      (asset: any) => asset.itemId === item.itemId,
    )
    if (artworkItem) {
      const selectedOption = getSelectedColorForAsset(artworkItem)

      let gradient: any = null
      if (selectedOption && selectedOption.multiColors) {
        gradient = getGradientColor(selectedOption.multiColors)
      }

      let positionObj = {
        left: artworkItem.x + canvasProps.x,
        top: artworkItem.y + canvasProps.y,
        angle: artworkItem.angle,
      }

      if (flipX && canvasObj.flipX) {
        positionObj.left = canvasProps.width - positionObj.left
        positionObj.angle = -positionObj.angle
      }

      Promise.all([
        new FontFaceObserver('BrushScript').load(null, 5000),
        new FontFaceObserver('CenturyGothic').load(null, 5000),
      ]).then(() => {
        const obj = new fabric.Text(item.value || '', {
          ...positionObj,
          fill:
            gradient ||
            darkenColor(
              (selectedOption && selectedOption.code) || '#000000',
              50,
            ),
          originX: 'center',
          originY: 'center',
          fontFamily: item.font || 'BrushScript',
          fontSize: artworkItem.fontSize,
          textAlign: 'center',
          selectable: false,
          evented: false,
        })

        canvas.add(obj)
        objects.artwork[item.itemId] = obj
      })
    }
  }

  function renderEngraving() {
    const { objects } = canvasObj
    const palmItem = itemList.find((item) => item.item === 'Palm')
    let selectedPatternIndex = -1

    if (!objects.engraving) {
      objects.engraving = {}
    }
    if (palmItem.customPattern) {
      selectedPatternIndex = palmItem.customPattern.findIndex(
        (pattern: any) => !!pattern.selected,
      )
    }

    if (
      selectedPatternIndex > -1 &&
      palmItem.customPattern[selectedPatternIndex].src
    ) {
      loadExternalImage(palmItem.customPattern[selectedPatternIndex].src).then(
        (img: any) => {
          drawEngraving(palmItem, selectedPatternIndex, img)
          updateAssetColors()
        },
      )
    } else {
      drawEngraving(palmItem, -1)
    }
  }

  function drawEngraving(
    palmItem: any,
    patternIndex: number,
    engraveImg?: any,
  ) {
    const {
      canvas,
      imageAssets,
      loadedImages: { engraving },
      objects,
      imageColors,
    } = canvasObj

    if (objects.engraving[0]) {
      canvas.remove(objects.engraving[0])
      objects.engraving = {}
      imageColors.engraving = {}
    }

    imageAssets.engraving.forEach((asset: any, i: number) => {
      const engravingImg = engraveImg || engraving[i]
      let positionObj: any = {
        x: asset.x + canvasProps.x,
        y: asset.y + canvasProps.y,
      }
      if (flipX && canvasObj.flipX) {
        positionObj.x = canvasProps.width - positionObj.x
      }

      const containerSize = 200
      const maxImageSize = Math.max(engravingImg.width, engravingImg.height)
      const ratio = containerSize / maxImageSize

      positionObj.scale = ratio
      positionObj.angle = 0

      if (palmItem && patternIndex > -1) {
        if (palmItem.customPattern[patternIndex].position) {
          positionObj = palmItem.customPattern[patternIndex].position
        } else {
          palmItem.customPattern[patternIndex].position = positionObj
        }
      }

      const obj: any = new fabric.Image(engravingImg, {
        left: positionObj.x,
        top: positionObj.y,
        width: engravingImg.width,
        height: engravingImg.height,
        originX: 'center',
        originY: 'center',
        centeredScaling: true,
        angle: positionObj.angle,
        globalCompositeOperation: 'source-over',
        selectable: isAdmin && !prefix && !!engraveImg,
        name: asset.itemId,
        borderColor: '#FD567F',
        borderDashArray: [16, 12],
        borderScaleFactor: 3,
        borderOpacityWhenMoving: 0.8,
        padding: 10,
        cornerStyle: 'circle',
        cornerSize: 24,
        cornerColor: '#FD567F',
        cornerStrokeColor: 'white',
      })
      obj.scale(positionObj.scale)

      if (patternIndex > -1) {
        obj.onSelect = function () {
          canvasObj.activeObject = palmItem.customPattern[patternIndex].position
        }
        obj.onDeselect = function () {
          canvasObj.activeObject = null
          if (canvasObj.tooltip) {
            document.body.removeChild(canvasObj.tooltip)
            canvasObj.tooltip = null
          }

          if (onViewUpdate) onViewUpdate()
        }

        // prevent resizing on sides
        obj.setControlsVisibility({
          mb: false,
          ml: false,
          mr: false,
          mt: false,
        })
      }

      canvas.add(obj)
      objects.engraving[i] = obj

      const selectedOption = getSelectedColorForAsset(asset)
      if (selectedOption && selectedOption.code) {
        let color = selectedOption.code

        if (asset.darken) {
          color = darkenColor(
            color,
            getLightColorPercentage(color) * asset.darken * 0.1,
          )
        }

        applyColor({
          obj,
          img: engravingImg,
          color,
          blendMode: asset.blendMode,
        })

        setTimeout(() => {
          canvasObj.canvas.requestRenderAll()
        }, 1)
      }
    })
  }

  function renderPlayerInfo() {
    const { canvas, objects } = canvasObj

    if (objects.playerInfo) {
      canvas.remove(objects.playerInfo)
      objects.playerInfo = null
    }

    const playerInfoItem = itemList.find(
      (item) => item.itemType === 'playerInfo' && item.showEmbroidery,
    )

    if (!playerInfoItem) return

    let selectedPatternIndex = -1

    if (playerInfoItem.customPattern) {
      selectedPatternIndex = playerInfoItem.customPattern.findIndex(
        (pattern: any) => !!pattern.selected,
      )
    }
    if (
      selectedPatternIndex > -1 &&
      playerInfoItem.customPattern[selectedPatternIndex].src
    ) {
      renderEmbArtwork(playerInfoItem)
    } else {
      const selectedOption = getSelectedColorForAsset(playerInfoItem)

      let gradient: any = null
      if (selectedOption && selectedOption.multiColors) {
        gradient = getGradientColor(selectedOption.multiColors)
      }

      const text = playerInfoItem.value || ''
      const position: any = {}
      let fontSize = 36
      if (viewType === 'Front Right') {
        position.left = 102
        position.top = 1418
        fontSize = text.length > 2 ? 36 : 44
      } else if (viewType === 'Outside') {
        position.left = 320
        position.top = 1200
        fontSize = text.length > 2 ? 40 : 48
      }

      let textOptions: any = {
        ...position,
        fill: gradient || (selectedOption ? selectedOption.code : '#000'),
        originX: 'center',
        originY: 'center',
        fontFamily: 'Swiss',
        fontSize: fontSize,
        textAlign: 'center',
        selectable: false,
      }

      if (playerInfoItem.stroke) {
        const selectedStroke = playerInfoItem.strokeColor.find(
          (option: any) => !!option.selected,
        )
        let strokeGradient: any = null
        if (selectedStroke && selectedStroke.multiColors) {
          strokeGradient = getGradientColor(selectedStroke.multiColors)
        }
        textOptions = {
          ...textOptions,
          stroke: strokeGradient || (selectedStroke && selectedStroke.code),
          strokeWidth: 1.2,
        }
      }

      new FontFaceObserver('Swiss').load(null, 5000).then(() => {
        const obj = new fabric.Text(text, textOptions)

        canvas.add(obj)
        objects.playerInfo = obj
      })
    }
  }

  function updateAssetColors() {
    const {
      itemListById,
      imageAssets,
      loadedImages,
      imageColors,
      objects,
      swoosh,
      web,
      palm,
      finger,
    } = canvasObj

    Object.keys(imageAssets).forEach((key: any) => {
      if (
        key === 'assets' ||
        key === swoosh.pattern ||
        key === web.pattern ||
        key === palm.pattern ||
        key === finger.pattern
      ) {
        let assets = imageAssets[key]

        if (!imageColors[key]) {
          imageColors[key] = {}
        }

        assets.forEach(function (asset: any, i: number) {
          if (asset.itemId && objects[key][i]) {
            const obj = objects[key][i]
            const selectedOption = getSelectedColorForAsset(asset)

            if (selectedOption && selectedOption.code) {
              if (
                selectedOption.code !== imageColors[key][i] ||
                asset.overlay
              ) {
                if (selectedOption.multiColors) {
                  applyColor({
                    obj,
                    img: loadedImages[key][i],
                    color: selectedOption.multiColors,
                    blendMode: asset.blendMode,
                  })
                } else {
                  let color = selectedOption.code

                  if (
                    viewType === 'Palm of Hand' &&
                    productType.indexOf('Fielding Glove') === -1
                  ) {
                    color = darkenColor(color, getLightColorPercentage(color))
                  }

                  if (
                    ['Thumb Embroidery', 'Pinky Embroidery'].indexOf(
                      itemListById[asset.itemId].item,
                    ) > -1
                  ) {
                    color = darkenColor(
                      color,
                      getLightColorPercentage(color) * 2.5,
                    )
                  } else if (asset.darken) {
                    color = darkenColor(
                      color,
                      getLightColorPercentage(color) * asset.darken * 0.1,
                    )
                  }
                  applyColor({
                    obj,
                    img: loadedImages[key][i],
                    color,
                    blendMode: asset.blendMode,
                  })
                }

                imageColors[key][i] = selectedOption.code
              }
            } else {
              imageColors[key][i] = null
            }
          }
        })
      }
    })
  }

  function updateExternalAssets() {
    setCurrentState(13)

    const { imageAssets } = canvasObj
    let hasChangedAssets = false

    const customPatternData: any = checkPalmCustomPattern()
    if (customPatternData.isChanged) {
      renderPalmCustomPattern(
        customPatternData.material,
        customPatternData.pattern,
        customPatternData.src,
      )
      hasChangedAssets = true
    }

    if (viewType === 'Die Cast' || viewType === 'Small Die Cast') {
      renderDieCastCustomPattern()
      renderDieCastLogos()
      hasChangedAssets = true
    }

    if (hasChangedAssets) {
      if (imageAssets['3dLayer']) {
        render3dLayer()
      }

      renderOverlayAssets()

      updateAssetColors()

      const siliconeData: any = checkSilicone()
      if (siliconeData.isChanged) {
        renderSilicone(siliconeData.pattern)
      }
    }

    const logoData: any = checkPalmLogos()
    if (logoData.isChanged) {
      renderPalmLogos()
    }

    setCurrentState(14)
  }

  //get the selected color for related item
  function getSelectedColorForAsset(asset: any) {
    const { itemListById } = canvasObj
    const relatedItem = itemListById[asset.itemId]
    let selectedOption = null
    if (relatedItem) {
      if (relatedItem.itemType === 'swoosh') {
        if (asset.secondary) {
          selectedOption = relatedItem.secondaryColors.find(
            (option: any) => !!option.selected,
          )
        } else {
          selectedOption = relatedItem.primaryColors.find(
            (option: any) => !!option.selected,
          )
        }
      } else if (relatedItem.itemType === 'palm') {
        if (relatedItem.material === 'Leather') {
          selectedOption = { code: '#bfc7c5' }
        } else if (relatedItem.material === 'Hydragrip') {
          selectedOption = { code: '#121519' }
        } else {
          if (asset.secondary) {
            selectedOption = relatedItem.secondaryColors.find(
              (option: any) => !!option.selected,
            )
          } else if (asset.tertiary) {
            selectedOption = relatedItem.tertiaryColors.find(
              (option: any) => !!option.selected,
            )
          } else {
            if (
              (relatedItem.pattern === 'Solid' ||
                relatedItem.pattern === 'Glitter') &&
              asset.position
            ) {
              selectedOption = relatedItem.primaryColors.find(
                (option: any) =>
                  !!option.selected &&
                  option.position &&
                  option.position.indexOf(asset.position) > -1,
              )
            } else {
              selectedOption = relatedItem.primaryColors.find(
                (option: any) => !!option.selected,
              )
            }
          }
        }
      } else if (relatedItem.itemType === 'dieCast') {
        selectedOption = relatedItem.primaryColors.find(
          (option: any) =>
            !!option.selected &&
            option.position &&
            option.position.indexOf(asset.position) > -1,
        )
      } else {
        selectedOption = relatedItem.options.find(
          (option: any) => !!option.selected,
        )

        if (!selectedOption && relatedItem.optionalColors) {
          selectedOption = relatedItem.optionalColors.find(
            (option: any) => !!option.selected,
          )
        }
      }
    }

    return selectedOption
  }

  function applyColor({
    obj,
    img,
    color,
    blendMode,
    alpha,
  }: {
    obj: any
    img: any
    color: string | string[]
    blendMode?: string
    alpha?: number
  }) {
    const { helperCanvas, helperCanvasContext } = canvasObj

    helperCanvas.width = img.width
    helperCanvas.height = img.height
    helperCanvasContext.clearRect(0, 0, img.width, img.height)

    helperCanvasContext.save()

    if (color instanceof Array) {
      const gradient = helperCanvasContext.createLinearGradient(
        0,
        0,
        img.width,
        0,
      )
      const partitions = Math.ceil(Math.max(img.width, img.height) / 40)
      const colorCount = color.length
      for (let i = 0; i < partitions; i++) {
        gradient.addColorStop(i / partitions, color[i % colorCount])
      }
      helperCanvasContext.fillStyle = gradient
    } else {
      helperCanvasContext.fillStyle = color
    }

    if (alpha) {
      helperCanvasContext.globalAlpha = alpha
    }

    helperCanvasContext.fillRect(0, 0, img.width, img.height)
    helperCanvasContext.globalCompositeOperation = 'destination-in'
    helperCanvasContext.drawImage(img, 0, 0)

    if (blendMode !== 'no-blend') {
      helperCanvasContext.globalCompositeOperation = blendMode || 'multiply'
      helperCanvasContext.drawImage(img, 0, 0)
    }

    helperCanvasContext.restore()

    obj.setSrc(helperCanvas.toDataURL())
  }

  function getGradientColor(colors: string[]) {
    const { helperCanvas, helperCanvasContext } = canvasObj

    helperCanvasContext.clearRect(0, 0, 200, 200)

    helperCanvas.width = 200
    helperCanvas.height = 200
    const gradient = helperCanvasContext.createLinearGradient(
      -200,
      -200,
      200,
      200,
    )
    const partitions = Math.ceil(400 / 20)
    const colorCount = colors.length
    for (let i = 0; i < partitions; i++) {
      gradient.addColorStop(i / partitions, colors[i % colorCount])
    }

    return gradient
  }

  function calculateCanvasDimensions() {
    if (canvasObj) {
      setCurrentState(3)

      const { imageAssets } = canvasObj
      const { assets = {}, shadow = {} } = canvasObj.loadedImages
      let x = 0,
        y = 0,
        largestImageWidth = 0,
        largestImageHeight = 0,
        negativeOffsetX = 0,
        negativeOffsetY = 0

      Object.values(assets).forEach((image: any, index: number) => {
        const { x, y } = imageAssets.assets[index]
        if (image.width + x > largestImageWidth) {
          largestImageWidth = image.width + x
        }
        if (image.height + y > largestImageHeight) {
          largestImageHeight = image.height + y
        }

        if (negativeOffsetX > x) {
          negativeOffsetX = x
        }
        if (negativeOffsetY > y) {
          negativeOffsetY = y
        }
      })

      if (shadow && !prefix) {
        Object.values(shadow).forEach((image: any, index: number) => {
          const { x, y } = imageAssets.shadow[index]

          if (image.width + x > largestImageWidth) {
            largestImageWidth = image.width + x
          }
          if (image.height + y > largestImageHeight) {
            largestImageHeight = image.height + y
          }

          if (negativeOffsetX > x) {
            negativeOffsetX = x
          }
          if (negativeOffsetY > y) {
            negativeOffsetY = y
          }
        })
      }

      x = Math.abs(negativeOffsetX)
      y = Math.abs(negativeOffsetY)

      largestImageWidth = largestImageWidth + Math.abs(negativeOffsetX) + 2
      largestImageHeight = largestImageHeight + Math.abs(negativeOffsetY) + 2

      if (styleName) {
        y = y + 90
        largestImageHeight = largestImageHeight + 90
      }
      if (['Die Cast', 'Small Die Cast'].indexOf(viewType) > -1) {
        x = x + 150
        y = y + 50
        largestImageWidth = largestImageWidth + 300
        largestImageHeight = largestImageHeight + 100
      }
      if (productType.indexOf('Fielding Glove') > -1) {
        if (viewType === 'Side of Hand') {
          y = y + 15
          largestImageHeight = largestImageHeight + 15
        } else if (viewType === 'Back of Hand') {
          y = y + 35
          largestImageHeight = largestImageHeight + 35
        }
      }

      setCanvasProps({
        x,
        y,
        width: largestImageWidth,
        height: largestImageHeight,
      })

      setCurrentState(4)
    }
  }

  function checkSwoosh() {
    const { swoosh } = canvasObj
    const data: any = {}

    if (viewType === 'Top of Hand') {
      const swooshItem = itemList.find((item) => item.itemType === 'swoosh')

      if (swooshItem) {
        const swooshPattern = (
          swooshItem.brand +
          '-' +
          swooshItem.pattern
        ).toLowerCase()

        if (swoosh.pattern !== swooshPattern) {
          data.pattern = swooshPattern
          data.isChanged = true
        }
      }
    }

    return data
  }

  function checkWeb() {
    const { web } = canvasObj
    const data: any = {}

    if (viewType !== 'Back of Hand') {
      const webItem = itemList.find((item) => item.itemType === 'web')

      if (webItem) {
        const webPattern = webItem.pattern
          .toLowerCase()
          .replace('/', '')
          .replace(/\s/g, '-')

        if (web.pattern !== webPattern) {
          data.pattern = webPattern
          data.isChanged = true
        }
      }
    }

    return data
  }

  function checkSilicone() {
    const { silicone } = canvasObj
    const data: any = {}

    if (viewType === 'Top of Hand') {
      const siliconeItem = itemList.find((item) => item.itemType === 'silicone')

      if (siliconeItem) {
        if (silicone.pattern !== siliconeItem.pattern) {
          data.pattern = siliconeItem.pattern
          data.isChanged = true
        }
      }
    }

    return data
  }

  function checkFinger() {
    const data: any = {}

    if (viewType !== 'Palm of Hand') {
      const fingerItem = itemList.find((item) => item.itemType === 'finger')

      if (fingerItem) {
        const selectedOption = fingerItem.selectOptions.find(
          (option: any) => !!option.selected,
        )
        if (selectedOption) {
          const pattern = selectedOption.value
            .toLowerCase()
            .replace('/', '')
            .replace(/\s/g, '-')
          if (fingerItem.pattern !== pattern) {
            data.pattern = pattern
            data.isChanged = true
          }
        }
      }
    }

    return data
  }

  function checkPalm() {
    const { palm } = canvasObj
    const data: any = {}

    if (viewType === 'Top of Hand' || viewType === 'Palm of Hand') {
      const palmItem = itemList.find((item) => item.itemType === 'palm')

      if (palmItem) {
        const palmPattern = palmItem.pattern.toLowerCase().replace(/\s/g, '-')

        if (
          palm.material !== palmItem.material ||
          palm.pattern !== palmPattern
        ) {
          data.material = palmItem.material
          data.pattern = palmPattern
          data.isChanged = true
        }
      }
    }

    return data
  }

  function checkPalmCustomPattern() {
    const { customPattern } = canvasObj
    const data: any = {}

    if (viewType === 'Top of Hand' || viewType === 'Palm of Hand') {
      const palmItem = itemList.find((item) => item.itemType === 'palm')

      if (palmItem) {
        const palmPattern = palmItem.pattern.toLowerCase().replace(/\s/g, '-')
        const customPatternObj =
          (palmItem.customPattern &&
            palmItem.customPattern.find(
              (pattern: any) => !!pattern.selected,
            )) ||
          {}
        const customPatternSrc =
          (viewType === 'Top of Hand'
            ? customPatternObj.bohSrc
            : customPatternObj.src) || ''

        if (
          customPattern.material !== palmItem.material ||
          customPattern.pattern !== palmPattern ||
          customPattern.src !== customPatternSrc
        ) {
          data.material = palmItem.material
          data.pattern = palmPattern
          data.src = customPatternSrc
          data.isChanged = true
        }
      }
    }

    return data
  }

  function checkPalmLogos() {
    const { logos } = canvasObj
    const data: any = {}

    if (viewType === 'Palm of Hand') {
      const palmItem = itemList.find((item) => item.itemType === 'palm')

      if (palmItem && logos.material !== palmItem.material) {
        data.material = palmItem.material
        data.isChanged = true
      }
    }

    return data
  }

  return (
    <div className={classes.canvasContainer} style={styleProps}>
      {currentState < 2 && (
        <div className={classes.loadingContainer}>
          <Icon name="logo" />
        </div>
      )}
      <div className={classes.innerContainer}>
        <canvas
          id={
            (prefix ? prefix + '-' : '') +
            viewType.toLowerCase().replace(/\s/g, '-')
          }
          ref={canvasRef}
          style={{ maxWidth: '100%', maxHeight: '100%' }}></canvas>
        <canvas className={classes.helperCanvas} ref={helperCanvasRef}></canvas>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import classes from './select.module.scss'

interface Props {
  theme?: 'light' | 'dark'
  size?: 'medium' | 'large'
  align?: 'left' | 'right'
  menuStyles?: any
  options: any[]
  value: string
  onSelect?: (option: any) => void
}

export default function Select({
  theme = 'light',
  size = 'medium',
  align = 'left',
  menuStyles = {},
  options,
  value,
  onSelect,
}: Props) {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  })

  const handleDocumentClick = (e: any) => {
    if (!e.target.closest('.select-container')) {
      setShowMenu(false)
    }
  }

  const selectOption = (option: any) => {
    if (onSelect) {
      onSelect(option)
    }
    setShowMenu(false)
  }

  return (
    <div
      className={`${classes.container} ${classes[theme]} ${classes[size]} ${classes[align]} select-container`}>
      <div
        className={classes.valueContainer}
        onClick={() => setShowMenu(!showMenu)}>
        <div className={classes.value}>
          {options.find((opt) => opt.value === value)?.label || '--'}
        </div>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      {showMenu && (
        <div className={classes.menu} style={menuStyles}>
          {options.map((option) => {
            return (
              <div
                key={option.value}
                className={classes.menuItem}
                onClick={() => selectOption(option)}>
                <div className={classes.menuOption}>{option.label}</div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

import {
  AlabasterSuede,
  BlackMesh,
  BlackSuede,
  CinderOrangeSuede,
  LogoAnimatedBlack,
  LogoAnimatedWhite,
} from './images'

const Images = {
  alabasterSuede: AlabasterSuede,
  blackMesh: BlackMesh,
  blackSuede: BlackSuede,
  cinderOrangeSuede: CinderOrangeSuede,
  LogoAnimatedBlack: LogoAnimatedBlack,
  logoAnimatedWhite: LogoAnimatedWhite,
}

export default Images

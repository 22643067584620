import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  nikeLogo,
  nikeLogoShadow,
  nikeLogoStitch,
  nikeLogoOutline,
  blackSuede,
  cinderOrangeSuede,
  alabasterSuede,
} from './assets'
import { web1 as bweb1, web2 as bweb2, web3 as bweb3 } from './webs/basket'
import {
  web1 as btweb1,
  web2 as btweb2,
  web3 as btweb3,
  web4 as btweb4,
  web5 as btweb5,
  web6 as btweb6,
  web7 as btweb7,
} from './webs/basket-trap'
import {
  web1 as sweb1,
  web2 as sweb2,
  web3 as sweb3,
  web4 as sweb4,
  web5 as sweb5,
  web6 as sweb6,
  web7 as sweb7,
} from './webs/swoosh'
import {
  web1 as fkweb1,
  web2 as fkweb2,
  web3 as fkweb3,
  web4 as fkweb4,
  web5 as fkweb5,
} from './webs/full-k'
import {
  web1 as hweb1,
  web2 as hweb2,
  web3 as hweb3,
  web4 as hweb4,
  web5 as hweb5,
} from './webs/h'
import {
  web1 as iweb1,
  web2 as iweb2,
  web3 as iweb3,
  web4 as iweb4,
} from './webs/i'
import { web1 as mbweb1, web2 as mbweb2, web3 as mbweb3 } from './webs/mod-bar'
import {
  web1 as mbkweb1,
  web2 as mbkweb2,
  web3 as mbkweb3,
  web4 as mbkweb4,
  web5 as mbkweb5,
} from './webs/mod-basket'
import { web1 as tweb1, web2 as tweb2 } from './webs/trap'
import {
  web1 as vweb1,
  web2 as vweb2,
  web3 as vweb3,
  web4 as vweb4,
  web5 as vweb5,
} from './webs/v'

const assets: any = [
  {
    src: asset1,
    x: 0,
    y: 0,
    itemId: 101,
  },
  {
    src: asset5,
    x: 247.4613,
    y: 41.3661,
    itemId: 110,
  },
  {
    src: asset4,
    x: -6.8739,
    y: 177.045,
    itemId: 109,
  },

  {
    src: asset2,
    x: 50.4255,
    y: 55.1988,
    itemId: 108,
  },

  { src: asset3, x: 55.2291, y: 330.1791, itemId: 101 },
  {
    src: asset6,
    x: 8.7429,
    y: 56.1564,
    itemId: 111,
  },

  { src: nikeLogoOutline, x: 121.5323, y: 249.8343, itemId: 107 },
  { src: nikeLogoStitch, x: 126.2471, y: 254.5023, itemId: 107, darken: 40 },
  { src: nikeLogo, x: 122.6942, y: 256.8438, itemId: 106 },
  {
    src: nikeLogoShadow,
    x: 122.6924,
    y: 283.848,
    globalComposition: 'multiply',
  },

  { itemId: 113, x: 450, y: 385, fontSize: 62, angle: 12 },
]

const webs = {
  basket: [
    { src: bweb1, x: 369.2796, y: 22.0638, itemId: 100 },
    { src: bweb2, x: 399.7551, y: 55.4124, itemId: 111 },
    { src: bweb3, x: 360.7161, y: 45.7017, itemId: 108 },
  ],
  'basket-trap': [
    { src: btweb1, x: 408.0129, y: 45.8634, itemId: 100 },
    { src: btweb2, x: 437.6826, y: 70.5831, itemId: 111 },
    { src: btweb3, x: 834.0702, y: 418.6551, itemId: 100 },
    { src: btweb4, x: 827.6865, y: 123.2034, itemId: 108 },
    { src: btweb5, x: 799.1631, y: 141.9267, itemId: 100 },
    { src: btweb6, x: 806.2647, y: 147.8844, itemId: 111 },
    { src: btweb7, x: 407.7165, y: 65.3547, itemId: 108 },
  ],
  swoosh: [
    { src: sweb1, x: 399.5019, y: 34.6143, itemId: 100 },
    { src: sweb2, x: 429.1719, y: 59.334, itemId: 111 },
    { src: sweb3, x: 819.1755, y: 111.9543, itemId: 100 },
    { src: sweb4, x: 790.6524, y: 130.6776, itemId: 100 },
    { src: sweb5, x: 797.754, y: 136.6353, itemId: 111 },
    { src: sweb6, x: 399.2058, y: 54.1056, itemId: 108 },
    { src: sweb7, x: 619.6503, y: 162.4743, itemId: 106 },
  ],
  'full-k': [
    { src: fkweb1, x: 369.2796, y: 22.0638, itemId: 100 },
    { src: fkweb2, x: 399.7551, y: 55.4124, itemId: 111 },
    { src: fkweb3, x: 360.7161, y: 45.7017, itemId: 108 },
    { src: fkweb4, x: 403.6426, y: 52.2541, itemId: 100 },
    { src: fkweb5, x: 503.2125, y: 146.362, itemId: 111 },
  ],
  h: [
    { src: hweb1, x: 290.7418, y: 19.191, itemId: 100 },
    { src: hweb2, x: 303.4744, y: 24.5817, itemId: 111 },
    { src: hweb3, x: 429.3154, y: 56.6736, itemId: 100 },
    { src: hweb4, x: 430.8724, y: 64.0518, itemId: 111 },
    { src: hweb5, x: 313.4815, y: 42.3093, itemId: 108 },
  ],
  i: [
    { src: iweb1, x: 481.1319, y: 73.2549, itemId: 100 },
    { src: iweb2, x: 518.3907, y: 80.2626, itemId: 111 },
    { src: iweb3, x: 489.3501, y: 67.5816, itemId: 108 },
    { src: iweb4, x: 380.4753, y: 86.6208, itemId: 108 },
  ],
  'mod-bar': [
    { src: mbweb1, x: 362.2602, y: 27.1062, itemId: 100 },
    { src: mbweb2, x: 370.1571, y: 40.1415, itemId: 111 },
    { src: mbweb3, x: 374.9529, y: -10.3734, itemId: 108 },
  ],
  'mod-basket': [
    { src: mbkweb1, x: 413.5695, y: 23.4342, itemId: 100 },
    { src: mbkweb2, x: 453.4905, y: 55.5582, itemId: 111 },
    { src: mbkweb3, x: 797.8542, y: 119.3874, itemId: 100 },
    { src: mbkweb4, x: 805.1676, y: 124.9719, itemId: 111 },
    { src: mbkweb5, x: 374.0517, y: 49.4868, itemId: 108 },
  ],
  trap: [
    { src: tweb1, x: 341.3968, y: 101.3369, itemId: 100 },
    { src: tweb2, x: 366.0895, y: 48.8114, itemId: 108 },
  ],
  v: [
    { src: vweb1, x: 371.4111, y: 23.7243, itemId: 100 },
    { src: vweb2, x: 399.756, y: 55.4106, itemId: 111 },
    { src: vweb3, x: 782.2902, y: 119.1237, itemId: 100 },
    { src: vweb4, x: 794.6346, y: 131.4384, itemId: 111 },
    { src: vweb5, x: 360.7164, y: 43.5972, itemId: 108 },
  ],
}

const data = {
  assets,
  ...webs,
  'black-suede': [{ src: blackSuede, x: 0, y: 0, zindex: 1 }],
  'cinder-orange-suede': [{ src: cinderOrangeSuede, x: 0, y: 0, zindex: 1 }],
  'alabaster-suede': [{ src: alabasterSuede, x: 0, y: 0, zindex: 1 }],
}

export default data

import side from './side'
import back from './back'
import top from './top'
import palm from 'data/baseball/fielding-glove/palm'
import { config } from 'data/baseball/fielding-glove'

const data = {
  config,
  side,
  back,
  top,
  palm,
}

export default data

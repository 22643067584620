import React, { forwardRef, InputHTMLAttributes } from 'react'
import classes from './radio.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  theme?: 'light' | 'dark'
  children: any
}

const Radio = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { theme = 'light', children, ...rest } = props
  return (
    <div className={`${classes.container} ${classes[theme]}`}>
      <label className={classes.label}>
        <input type="radio" className={classes.radio} ref={ref} {...rest} />
        <span className={classes.checkContainer}>
          <span className={classes.check} />
        </span>
        {children}
      </label>
    </div>
  )
})

export default Radio

import React from 'react'

export default function Warn() {
  return (
    <svg
      fill="none"
      height="44"
      viewBox="0 0 38 44"
      width="38"
      xmlns="http://www.w3.org/2000/svg">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="38"
        x2="-4"
        y1="-6.5"
        y2="44">
        <stop offset="0" stopColor="#fbff26" />
        <stop offset="1" stopColor="#e78511" />
      </linearGradient>
      <path
        clipRule="evenodd"
        d="m18.9876 0 19.0124 10.9754v22l-19.0124 11.0246-18.9876-11.0246v-22zm-1.9034 12.882v11.7823h3.7586v-11.7823zm3.8073 14.3736h-3.783v3.8132h3.783z"
        fill="url(#a)"
        fillRule="evenodd"
      />
    </svg>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { SEASONS } from 'api/data/orders'
import {
  GET_PLAYER_ORDERS,
  GET_TEAM_ORDERS,
  GET_ORDER,
  UPDATE_ORDER,
  GET_SIZES,
} from './queries/orders'

//read
export function getSeasonsDropdownOptions() {
  return {
    seasons: SEASONS,
  }
}

export function useGetSizes(variables?: {
  sportId?: string
  position?: string
}) {
  return useQuery(GET_SIZES, {
    variables,
  })
}

export function useGetPlayerOrders(variables: { playerCode: string }) {
  return useQuery(GET_PLAYER_ORDERS, {
    variables,
    fetchPolicy: 'cache-and-network',
  })
}

export function useGetTeamOrders(variables: { teamId: string }) {
  return useQuery(GET_TEAM_ORDERS, {
    variables,
    fetchPolicy: 'cache-and-network',
  })
}

export function useGetOrder(variables: { orderId: string }) {
  return useQuery(GET_ORDER, { variables, fetchPolicy: 'no-cache' })
}

//update
export function useUpdateOrder(variables?: any, refetchQueries?: any[]) {
  return useMutation(UPDATE_ORDER, {
    variables,
    refetchQueries,
  })
}

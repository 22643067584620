import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation accessCodeLogin($accessCode: String) {
    accessCodeLogin(accessCode: $accessCode) {
      status
      message
      isPlayer
      isTeam
      accessToken
      data {
        _id
        name
        accessCode
        playerCode
        sportId
        brand
        profileLogo
        teamId
        teamName
      }
    }
  }
`

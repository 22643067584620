import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  nikeLogo,
  nikeLogoShadow,
  nikeLogoStitch,
  nikeLogoOutline,
  fingerPadIndex,
  fingerPadIndexStitch,
  fingerPadMiddle,
  fingerPadMiddleStitch,
  fingerSleeveIndex,
  fingerSleeveIndexStitch,
  fingerSleeveMiddle,
  fingerSleeveMiddleStitch,
  blackMesh,
  blackSuede,
  cinderOrangeSuede,
  alabasterSuede,
} from './assets'
import { web1 as bweb1, web2 as bweb2, web3 as bweb3 } from './webs/basket'
import {
  web1 as btweb1,
  web2 as btweb2,
  web3 as btweb3,
  web4 as btweb4,
  web5 as btweb5,
  web6 as btweb6,
  web7 as btweb7,
} from './webs/basket-trap'
import {
  web1 as bwwweb1,
  web2 as bwwweb2,
  web3 as bwwweb3,
  web4 as bwwweb4,
  web5 as bwwweb5,
  web6 as bwwweb6,
  web7 as bwwweb7,
} from './webs/swoosh'
import {
  web1 as fkweb1,
  web2 as fkweb2,
  web3 as fkweb3,
  web4 as fkweb4,
  web5 as fkweb5,
} from './webs/full-k'
import {
  web1 as fkwebFlip1,
  web2 as fkwebFlip2,
  web3 as fkwebFlip3,
  web4 as fkwebFlip4,
  web5 as fkwebFlip5,
  web6 as fkwebFlip6,
  web7 as fkwebFlip7,
} from './webs/full-k-flip'
import {
  web1 as hweb1,
  web2 as hweb2,
  web3 as hweb3,
  web4 as hweb4,
  web5 as hweb5,
} from './webs/h'
import {
  web1 as iweb1,
  web2 as iweb2,
  web3 as iweb3,
  web4 as iweb4,
} from './webs/i'
import { web1 as mbweb1, web2 as mbweb2, web3 as mbweb3 } from './webs/mod-bar'
import {
  web1 as mbkweb1,
  web2 as mbkweb2,
  web3 as mbkweb3,
  web4 as mbkweb4,
  web5 as mbkweb5,
} from './webs/mod-basket'
import { web1 as tweb1, web2 as tweb2 } from './webs/trap'
import {
  web1 as vweb1,
  web2 as vweb2,
  web3 as vweb3,
  web4 as vweb4,
  web5 as vweb5,
} from './webs/v'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 101 },
  { src: asset2, x: 62.4642, y: 55.6896, itemId: 102 },
  { src: asset3, x: 62.9277, y: 165.7575, itemId: 103 },
  { src: asset4, x: 118.0203, y: 519.5571, itemId: 105 },
  { src: asset5, x: 150.6, y: 156.1725, itemId: 101 },
  { src: asset6, x: 0, y: 0 },
  { src: asset7, x: 48.9648, y: 369.6732, itemId: 111 },
  { src: asset8, x: 23.8968, y: -2.5515, itemId: 110 },
  { src: asset9, x: 41.8029, y: 511.3089, itemId: 109 },
  { src: asset10, x: -4.9035, y: 7.155, itemId: 108 },
  { src: asset11, x: 10.0743, y: 119.1483, itemId: 101, overlay: true },
  { src: nikeLogoOutline, x: 297.8831, y: 575.7977, itemId: 107 },
  {
    src: nikeLogoStitch,
    x: 304.1112,
    y: 581.6061,
    itemId: 107,
    darken: 40,
  },
  { src: nikeLogo, x: 304.1112, y: 581.6061, itemId: 106 },
  {
    src: nikeLogoShadow,
    x: 304.3584,
    y: 584.997,
    globalComposition: 'multiply',
  },
  { itemId: 113, x: 535, y: 560, fontSize: 40, angle: -64 },
  { itemId: 114, x: 48, y: 310, fontSize: 28, angle: 90 },
]

export const webs = {
  basket: [
    { src: bweb1, x: 370.6107, y: 15.5319, itemId: 100 },
    { src: bweb2, x: 395.3856, y: 23.3952, itemId: 111 },
    { src: bweb3, x: 377.7417, y: 15.5319, itemId: 108 },
  ],
  'basket-trap': [
    { src: btweb1, x: 370.5996, y: 33.9645, itemId: 100 },
    { src: btweb2, x: 388.8987, y: 40.6413, itemId: 111 },
    { src: btweb3, x: 583.7439, y: 153.3741, itemId: 100 },
    { src: btweb4, x: 375.4131, y: 14.238, itemId: 108 },
    { src: btweb5, x: 394.6176, y: 26.6052, itemId: 100 },
    { src: btweb6, x: 398.5788, y: 61.2546, itemId: 111 },
    { src: btweb7, x: 401.3184, y: 22.3755, itemId: 108 },
  ],
  swoosh: [
    { src: bwwweb1, x: 366.7815, y: 26.3238, itemId: 100 },
    { src: bwwweb2, x: 385.0806, y: 33.0006, itemId: 111 },
    { src: bwwweb3, x: 371.595, y: 6.5973, itemId: 100 },
    { src: bwwweb4, x: 390.7995, y: 18.9645, itemId: 100 },
    { src: bwwweb5, x: 394.7607, y: 53.6139, itemId: 111 },
    { src: bwwweb6, x: 401.2908, y: 14.7348, itemId: 108 },
    { src: bwwweb7, x: 429.8325, y: 219.1653, itemId: 106 },
  ],
  'full-k': [
    { src: fkweb1, x: 370.6107, y: 15.5319, itemId: 100 },
    { src: fkweb2, x: 395.3856, y: 23.3952, itemId: 111 },
    { src: fkweb3, x: 377.7417, y: 15.5319, itemId: 108 },
    { src: fkweb4, x: 390.7575, y: 62.1426, itemId: 100 },
    { src: fkweb5, x: 441.9624, y: 79.2855, itemId: 111 },
  ],
  'full-k-flip': [
    { src: fkwebFlip1, x: 102.5625, y: 23.1003, itemId: 100 },
    { src: fkwebFlip2, x: 109.7286, y: 25.9461, itemId: 111 },
    { src: fkwebFlip3, x: 153.7998, y: 65.8416, itemId: 100 },
    { src: fkwebFlip4, x: 158.7177, y: 66.3945, itemId: 111 },
    { src: fkwebFlip5, x: 105.3873, y: 23.1006, itemId: 100 },
    { src: fkwebFlip6, x: 119.2485, y: 55.0458, itemId: 111 },
    { src: fkwebFlip7, x: 108.7143, y: 18.0837, itemId: 108 },
  ],
  h: [
    { src: hweb1, x: 379.5138, y: 19.4517, itemId: 100 },
    { src: hweb2, x: 383.7561, y: 64.5309, itemId: 111 },
    { src: hweb3, x: 410.9376, y: 80.8671, itemId: 100 },
    { src: hweb4, x: 414.3948, y: 82.4649, itemId: 111 },
    { src: hweb5, x: 375.2892, y: 15.6096, itemId: 108 },
  ],
  i: [
    { src: iweb1, x: 387.0624, y: 24.9189, itemId: 100 },
    { src: iweb2, x: 400.7256, y: 63.729, itemId: 111 },
    { src: iweb3, x: 369.2184, y: 33.2214, itemId: 108 },
    { src: iweb4, x: 399.8247, y: 27.8745, itemId: 108 },
  ],
  'mod-bar': [
    { src: mbweb1, x: 381.0507, y: 17.5863, itemId: 100 },
    { src: mbweb2, x: 394.3188, y: 50.6961, itemId: 111 },
    { src: mbweb3, x: 336.9879, y: 12.3888, itemId: 108 },
  ],
  'mod-basket': [
    { src: mbkweb1, x: 360.8391, y: 43.0524, itemId: 100 },
    { src: mbkweb2, x: 375.2745, y: 47.5374, itemId: 111 },
    { src: mbkweb3, x: 371.9751, y: 44.1477, itemId: 100 },
    { src: mbkweb4, x: 384.681, y: 76.4121, itemId: 111 },
    { src: mbkweb5, x: 366.5985, y: 38.5881, itemId: 108 },
  ],
  trap: [
    { src: tweb1, x: 410.0339, y: 62.8956, itemId: 100 },
    { src: tweb2, x: 318.7847, y: 35.208, itemId: 108 },
  ],
  v: [
    { src: vweb1, x: 370.9647, y: 20.5482, itemId: 100 },
    { src: vweb2, x: 395.3853, y: 23.3937, itemId: 111 },
    { src: vweb3, x: 398.4936, y: 20.5467, itemId: 100 },
    { src: vweb4, x: 408.6069, y: 52.4919, itemId: 111 },
    { src: vweb5, x: 372.6318, y: 15.5313, itemId: 108 },
  ],
}

const data = {
  assets: assets,
  ...webs,
  'finger-pad-index-finger': [
    { src: fingerPadIndex, x: 255.3678, y: 301.7337, itemId: 101, zindex: 9 },
    {
      src: fingerPadIndexStitch,
      x: 259.4706,
      y: 306.8955,
      itemId: 111,
      zindex: 10,
    },
  ],
  'finger-pad-middle-finger': [
    { src: fingerPadMiddle, x: 154.6887, y: 300.5022, itemId: 101, zindex: 9 },
    {
      src: fingerPadMiddleStitch,
      x: 159.9813,
      y: 305.6667,
      itemId: 111,
      zindex: 10,
    },
  ],
  'finger-sleeve-index-finger': [
    {
      src: fingerSleeveIndex,
      x: 265.9548,
      y: 264.2427,
      itemId: 101,
    },
    {
      src: fingerSleeveIndexStitch,
      x: 269.1777,
      y: 267.4509,
      itemId: 111,
    },
  ],
  'finger-sleeve-middle-finger': [
    {
      src: fingerSleeveMiddle,
      x: 145.7811,
      y: 252.9015,
      itemId: 101,
    },
    {
      src: fingerSleeveMiddleStitch,
      x: 149.0037,
      y: 256.1097,
      itemId: 111,
    },
  ],
  'black-mesh': [{ src: blackMesh, x: 104.8662, y: 16.6746, zindex: 1 }],
  'black-suede': [{ src: blackSuede, x: 103.8462, y: 20.7675, zindex: 1 }],
  'cinder-orange-suede': [
    { src: cinderOrangeSuede, x: 103.8462, y: 20.7675, zindex: 1 },
  ],
  'alabaster-suede': [
    { src: alabasterSuede, x: 103.8462, y: 20.7675, zindex: 1 },
  ],
}

export default data

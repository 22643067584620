import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from 'react-router-dom'
import { PageNotFound } from 'pages'
import Loading from './loading'

const Login = lazy(() => {
  return Promise.all([
    import('../pages/login'),
    new Promise((resolve) => setTimeout(resolve, 800)),
  ]).then(([loginPage]) => loginPage)
})

const RouteCheck = lazy(() => import('./route-check'))

const Orders = lazy(() => {
  return Promise.all([
    import('../pages/orders'),
    import('../pages/order-detail'),
    new Promise((resolve) => setTimeout(resolve, 800)),
  ]).then(([orders]) => orders)
})

const OrderDetail = lazy(() => import('../pages/order-detail'))

export default function Routes() {
  return (
    <Router basename="/">
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/" element={<Login />} />
          <Route
            path="/orders"
            element={
              <RouteCheck>
                <Orders />
              </RouteCheck>
            }>
            <Route path=":orderId" element={<OrderDetail />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Switch>
      </Suspense>
    </Router>
  )
}

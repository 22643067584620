import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  asset16,
  asset17,
  asset18,
  asset19,
  asset20,
  asset21,
  asset22,
  asset23,
  asset24,
  asset25,
  asset26,
  asset27,
  asset28,
  asset29,
  asset30,
  asset31,
} from './assets'

const assets = [
  {
    src: asset1,
    x: 240.8988,
    y: 1179.332,
    itemId: 209,
  },
  {
    src: asset2,
    x: 251.3576,
    y: 335.8068,
    itemId: 209,
  },
  {
    src: asset3,
    x: 251.6624,
    y: 352.722,
    itemId: 213,
  },
  {
    src: asset4,
    x: 284.334,
    y: 896.2444,
    itemId: 213,
  },
  {
    src: asset5,
    x: 219.4476,
    y: 370.3916,
    itemId: 201,
  },
  {
    src: asset6,
    x: 220.0952,
    y: 442.2468,
    globalComposition: 'multiply',
  },
  {
    src: asset7,
    x: 286.022,
    y: 546.3488,
    itemId: 213,
  },
  {
    src: asset8,
    x: 358.4916,
    y: 492.4344,
  },
  {
    src: asset9,
    x: 0,
    y: 1208.796,
    itemId: 209,
  },
  {
    src: asset10,
    x: 3.5428,
    y: 1253.3756,
    itemId: 201,
  },
  {
    src: asset11,
    x: 6.2408,
    y: 1275.1452,
  },
  {
    src: asset12,
    x: 33.1708,
    y: 1295.9756,
    globalComposition: 'multiply',
  },
  {
    src: asset13,
    x: 149.9604,
    y: 1209.3768,
    itemId: 201,
  },
  {
    src: asset14,
    x: 176.9692,
    y: 1230.8288,
    globalComposition: 'multiply',
  },
  {
    src: asset15,
    x: 177.728,
    y: 1340.0804,
  },
  {
    src: asset16,
    x: 212.3424,
    y: 65.4232,
    itemId: 213,
  },
  {
    src: asset17,
    x: 212.3488,
    y: 65.4232,
    itemId: 209,
  },
  {
    src: asset18,
    x: 396.8188,
    y: 18.348,
    itemId: 209,
  },
  {
    src: asset19,
    x: 413.5664,
    y: 18.5616,
    itemId: 213,
  },
  {
    src: asset20,
    x: 328.4888,
    y: 10.0308,
    itemId: 201,
  },
  {
    src: asset21,
    x: 480.5716,
    y: 10.036,
    globalComposition: 'multiply',
  },
  {
    src: asset22,
    x: 344.1032,
    y: 89.6488,
  },
  {
    src: asset23,
    x: 374.7872,
    y: 118.0116,
  },
  {
    src: asset24,
    x: 192.41,
    y: 84.5016,
    itemId: 201,
  },
  {
    src: asset25,
    x: 197.104,
    y: 119.6772,
    globalComposition: 'multiply',
  },
  {
    src: asset26,
    x: 260.4032,
    y: 130.5872,
  },
  {
    src: asset27,
    x: 226.5524,
    y: 7.8476,
    itemId: 209,
  },
  {
    src: asset28,
    x: 231.8416,
    y: 8.93,
    itemId: 213,
  },
  {
    src: asset29,
    x: 230.5428,
    y: 0,
    itemId: 201,
  },
  {
    src: asset30,
    x: 243.036,
    y: 10.1108,
    globalComposition: 'multiply',
  },
  {
    src: asset31,
    x: 352.71,
    y: 15.304,
    itemId: 213,
  },
]

const data = {
  assets: assets,
}

export default data

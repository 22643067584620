import React from 'react'

export default function Moon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9756 17.4891C21.8306 21.3612 17.6111 23.995 12.7552 24C5.72477 24.0068 0 18.4726 0 11.6678C0 6.26819 3.58276 1.68036 8.57398 0.0113348C8.74349 -0.0453061 8.90088 0.120712 8.82824 0.278914C8.12398 1.81312 7.73252 3.51247 7.73554 5.2995C7.74563 12.1521 13.4693 17.6522 20.5491 17.6142C21.6519 17.6083 22.7224 17.4667 23.7424 17.205C23.9159 17.1601 24.0602 17.3378 23.9754 17.4902L23.9756 17.4891Z"
        fill="#D7D7D7"
      />
    </svg>
  )
}

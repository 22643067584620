import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  nikeLogo,
  nikeLogoShadow,
  nikeLogoStitch,
  nikeLogoOutline,
  blackSuede,
  cinderOrangeSuede,
  alabasterSuede,
} from './assets'
import { webs } from 'data/baseball/fielding-glove/top'

const assets = [
  { src: asset1, x: 0, y: -20, itemId: 101 },
  { src: asset9, x: 63.3345, y: 38.3338, itemId: 102 },
  { src: asset10, x: 62.0364, y: 36.1846 },
  { src: asset4, x: 23.8965, y: -17.6753, itemId: 110 },
  { src: asset6, x: 191.9538, y: 526.5106, itemId: 105 },
  { src: asset5, x: 171.5622, y: 512.3599, itemId: 109 },
  { src: asset2, x: -4.9038, y: -13.1853, itemId: 108 },
  { src: asset3, x: 10.074, y: 99.6565, itemId: 101 },
  { src: asset7, x: 213.5337, y: 351.2506, itemId: 111 },
  { src: asset8, x: 64.1958, y: 147.0538, itemId: 103 },
  { src: nikeLogoOutline, x: 399.3222, y: 506.7961, itemId: 107 },
  {
    src: nikeLogoStitch,
    x: 403.9926,
    y: 511.1977,
    itemId: 107,
    darken: 40,
  },
  { src: nikeLogo, x: 403.9926, y: 511.1977, itemId: 106 },
  {
    src: nikeLogoShadow,
    x: 375.1644,
    y: 513.7723,
    globalComposition: 'multiply',
  },
  { itemId: 113, x: 535, y: 560, fontSize: 40, angle: -64 },
  { itemId: 114, x: 48, y: 310, fontSize: 28, angle: 90 },
]

const data = {
  assets: assets,
  ...webs,
  'black-suede': [{ src: blackSuede, x: 0, y: -18, zindex: 1 }],
  'cinder-orange-suede': [{ src: cinderOrangeSuede, x: 0, y: -18, zindex: 1 }],
  'alabaster-suede': [{ src: alabasterSuede, x: 0, y: -18, zindex: 1 }],
}

export default data

import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useLogin } from 'api'
import { Button, Input, Icon } from 'components'
import classes from './login.module.scss'

export default function Login() {
  const navigate = useNavigate()
  const [inProgress, setInProgress] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [login] = useLogin()
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Login',
    })
  }, [])

  useEffect(() => {
    if (errors.accessCode || errorMessage) {
      setFocus('accessCode')
    }
  }, [errors, errorMessage])

  const handleLogin = (data: any) => {
    if (errors.accessCode || errorMessage || inProgress) return

    setInProgress(true)
    login({ variables: { accessCode: data.accessCode } }).then((res: any) => {
      const {
        accessCodeLogin: {
          status = false,
          message = 'Invalid Access Code',
          isPlayer,
          isTeam,
          accessToken,
          data,
        },
      } = res.data

      if (status) {
        sessionStorage.setItem(
          'user',
          JSON.stringify({ ...data, isPlayer, isTeam }),
        )
        sessionStorage.setItem('token', accessToken || '')
        sessionStorage.removeItem('filters')
        sessionStorage.removeItem('theme')
        navigate('/orders')
      } else {
        setErrorMessage(message)
        setInProgress(false)
      }
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <Icon name="logo" />
      </div>
      <div className={classes.loginContainer}>
        <div className={classes.title}>Enter your Access Code</div>
        <form
          className={classes.loginForm}
          onSubmit={handleSubmit(handleLogin)}>
          <Input
            className={`${classes.accessCode} ${
              errors.accessCode || errorMessage ? classes.error : ''
            }`}
            {...register('accessCode', { required: true })}
            onKeyDown={() => setErrorMessage('')}
            autoFocus
          />
          {errors.accessCode && (
            <div className={classes.errorMsg}>Please Enter Access Code</div>
          )}
          {errorMessage && (
            <div className={classes.errorMsg}>Invalid Access Code</div>
          )}
          <Button
            variant="primary"
            size="large"
            type="submit"
            inProgress={inProgress}>
            Login
          </Button>
        </form>
      </div>
      <div className={classes.message}>
        By entering the website, you acknowledge that these designs are
        confidential Nike and JR286 designs. Any use or reproduction of these
        materials is strictly prohibited. Do not post these designs on social
        media. Keep it tight.
      </div>
    </div>
  )
}

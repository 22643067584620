import React from 'react'

export default function Type() {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m4 7c0-.93188 0-1.39782.15224-1.76537.20299-.49005.59234-.8794 1.08239-1.08239.36755-.15224.83349-.15224 1.76537-.15224h10c.9319 0 1.3978 0 1.7654.15224.49.20299.8794.59234 1.0824 1.08239.1522.36755.1522.83349.1522 1.76537m-11 13h6m-3-16v16"
        stroke="#e9edf0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
    </svg>
  )
}

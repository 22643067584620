import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  placeholder,
  engraving,
} from './assets'
import { web1 as bweb1, web2 as bweb2, web3 as bweb3 } from './webs/basket'
import {
  web1 as btweb1,
  web2 as btweb2,
  web3 as btweb3,
  web4 as btweb4,
  web5 as btweb5,
  web6 as btweb6,
  web7 as btweb7,
} from './webs/basket-trap'
import {
  web1 as sweb1,
  web2 as sweb2,
  web3 as sweb3,
  web4 as sweb4,
  web5 as sweb5,
  web6 as sweb6,
} from './webs/swoosh'
import { web1 as fkweb1, web2 as fkweb2, web3 as fkweb3 } from './webs/full-k'
import {
  web1 as hweb1,
  web2 as hweb2,
  web3 as hweb3,
  web4 as hweb4,
  web5 as hweb5,
} from './webs/h'
import {
  web1 as iweb1,
  web2 as iweb2,
  web3 as iweb3,
  web4 as iweb4,
} from './webs/i'
import {
  web1 as mbweb1,
  web2 as mbweb2,
  web3 as mbweb3,
  web4 as mbweb4,
} from './webs/mod-bar'
import {
  web1 as mbkweb1,
  web2 as mbkweb2,
  web3 as mbkweb3,
  web4 as mbkweb4,
  web5 as mbkweb5,
} from './webs/mod-basket'
import { web1 as tweb1, web2 as tweb2 } from './webs/trap'
import {
  web1 as vweb1,
  web2 as vweb2,
  web3 as vweb3,
  web4 as vweb4,
  web5 as vweb5,
} from './webs/v'

const assets = [
  { src: asset1, x: 23.3562, y: 528.6723, itemId: 101, overlay: true },
  { src: asset2, x: -59.9685, y: -27.9678, itemId: 108, overlay: true },
  { src: asset3, x: 0, y: 0, itemId: 101, overlay: true },
  { src: asset4, x: 480.2571, y: 38.6856, itemId: 102, overlay: true },
  { src: asset5, x: 545.6508, y: 158.5392, itemId: 103, overlay: true },
  { src: asset6, x: 264.7758, y: 726.2115, itemId: 105, overlay: true },
  { src: asset7, x: 163.8246, y: 0.7386, itemId: 110, overlay: true },
  { src: asset8, x: 22.4526, y: 18.3249, itemId: 104, overlay: true },
  { src: asset9, x: 119.3601, y: 478.5561, itemId: 109, overlay: true },
  { src: asset10, x: 45.2808, y: 36.2814, itemId: 108, overlay: true },
  { src: placeholder, x: 0, y: 0, itemId: 111 },
]

const webs = {
  basket: [
    { src: bweb1, x: 35.7093, y: 8.9127, itemId: 100 },
    { src: bweb2, x: 41.5494, y: 12.2613, itemId: 111 },
    { src: bweb3, x: 40.278, y: 22.5417, itemId: 108 },
  ],
  'basket-trap': [
    { src: btweb1, x: 32.841, y: 41.6415, itemId: 100 },
    { src: btweb2, x: 35.8908, y: 48.5604, itemId: 111 },
    { src: btweb3, x: 189.5478, y: 15.6219, itemId: 100 },
    { src: btweb4, x: 18.0264, y: 210.5601, itemId: 108 },
    { src: btweb5, x: 30.4926, y: 35.5434, itemId: 100 },
    { src: btweb6, x: 49.6017, y: 65.7384, itemId: 111 },
    { src: btweb7, x: 34.1121, y: 38.0517, itemId: 108 },
  ],
  swoosh: [
    { src: sweb1, x: 29.7474, y: 40.2105, itemId: 100 },
    { src: sweb2, x: 32.7975, y: 47.1294, itemId: 111 },
    { src: sweb3, x: 14.9331, y: 14.1909, itemId: 100 },
    { src: sweb4, x: 27.3993, y: 34.1127, itemId: 100 },
    { src: sweb5, x: 46.5084, y: 64.3074, itemId: 111 },
    { src: sweb6, x: 31.0188, y: 36.621, itemId: 108 },
  ],
  'full-k': [
    { src: fkweb1, x: 35.7093, y: 8.9127, itemId: 100 },
    { src: fkweb2, x: 41.5494, y: 12.2613, itemId: 111 },
    { src: fkweb3, x: 40.278, y: 22.5417, itemId: 108 },
  ],
  h: [
    { src: hweb1, x: 81.0849, y: 125.7303, itemId: 100, angle: -8 },
    { src: hweb2, x: 85.3125, y: 129.3444, itemId: 111, angle: -8 },
    { src: hweb3, x: 45.9663, y: 56.367, itemId: 100, angle: -8 },
    { src: hweb4, x: 66.993, y: 96.4506, itemId: 111, angle: -8 },
    { src: hweb5, x: 38.4584, y: 59.3757, itemId: 108, angle: -6 },
  ],
  i: [
    { src: iweb1, x: 121.242, y: 297.531, itemId: 108 },
    { src: iweb2, x: 13.4781, y: 21.7701, itemId: 100 },
    { src: iweb3, x: 55.2993, y: 79.6167, itemId: 111 },
    { src: iweb4, x: 14.433, y: 20.3565, itemId: 108 },
  ],
  'mod-bar': [
    { src: mbweb1, x: 128.9601, y: 260.7471, itemId: 108 },
    { src: mbweb2, x: 38.1594, y: 10.6983, itemId: 100 },
    { src: mbweb3, x: 57.9315, y: 53.6328, itemId: 111 },
    { src: mbweb4, x: 40.3701, y: 24.2106, itemId: 108 },
  ],
  'mod-basket': [
    { src: mbkweb1, x: 37.602, y: 8.9121, itemId: 100 },
    { src: mbkweb2, x: 41.5494, y: 12.2613, itemId: 111 },
    { src: mbkweb3, x: 38.0514, y: 8.9139, itemId: 100 },
    { src: mbkweb4, x: 57.9309, y: 53.6316, itemId: 111 },
    { src: mbkweb5, x: 40.2789, y: 22.5423, itemId: 108 },
  ],
  trap: [
    { src: tweb1, x: 99.2917, y: 97.6541, itemId: 100, angle: -8 },
    { src: tweb2, x: 22.3768, y: 61.253, itemId: 108, angle: -8 },
  ],
  v: [
    { src: vweb1, x: 37.6008, y: 8.9121, itemId: 100 },
    { src: vweb2, x: 73.962, y: 96.9207, itemId: 111 },
    { src: vweb3, x: 38.0505, y: 8.9142, itemId: 100 },
    { src: vweb4, x: 57.9303, y: 53.6337, itemId: 111 },
    { src: vweb5, x: 40.2783, y: 22.5426, itemId: 108 },
  ],
}

const data = {
  assets: assets,
  ...webs,
  engraving: [
    {
      src: engraving,
      x: 372,
      y: 445,
      itemId: 104,
      darken: 15,
      blendMode: 'no-blend',
    },
  ],
}

export default data

import React from 'react'
import { useGetTeamOrders } from 'api/orders'
import { objectPath } from 'helpers/object'
import OrdersList from './orders-list'
import classes from './orders.module.scss'

interface Props {
  user: any
  season: string
  onSelect: (order: any) => void
}

export default function TeamOrders({ user, season, onSelect }: Props) {
  const { loading, data } = useGetTeamOrders({
    teamId: user._id,
  })

  const ordersData = objectPath(data, ['teamOrders', '0'], [])
  const orders = objectPath(ordersData, ['orders'], [])
  const filteredOrders = orders.filter((order: any) => {
    return (
      !season ||
      [season, 'su' + season, 'fa' + season, 'ho' + season].indexOf(
        order.season,
      ) > -1
    )
  })

  if (loading) {
    return (
      <div className={classes.ordersList}>
        {[...Array(8)].map((val, index) => {
          return <div key={index} className={classes.productLoadingCard}></div>
        })}
      </div>
    )
  }

  if (filteredOrders.length === 0) {
    return (
      <div className={classes.errorMsg}>
        <div className={classes.header}>No Styles Found</div>
        <div className={classes.msg}>
          Try another season or check back later for new options
        </div>
      </div>
    )
  }

  return (
    <div className={classes.ordersList}>
      <OrdersList orders={filteredOrders} onSelect={onSelect} />
    </div>
  )
}

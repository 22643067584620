import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'providers'
import { Routes } from 'routes'
import ReactGA from 'react-ga4'

ReactGA.initialize(
  window.location.hostname === 'eqbuilder.com'
    ? 'G-P84CZEL5LK'
    : 'G-YD0796FXXW',
)

function App() {
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  )
}

export default App

import React from 'react'
import { Button, Icon } from 'components'
import classes from './page-not-found.module.scss'

interface Props {
  orders?: boolean
  onClose?: () => void
}

export default function PageNotFound({ orders, onClose }: Props) {
  return (
    <div className={classes.container}>
      <div className={classes.messageContainer}>
        <div className={classes.icon}>
          <Icon name="warn" />
        </div>
        <div className={classes.header}>Page Not Found</div>
        <div className={classes.message}>
          {`The page you are looking for is not found.${
            orders ? ' Please check orders list to see your orders.' : ''
          }`}
        </div>
        {orders && (
          <div className={classes.action}>
            <Button onClick={onClose}>Orders</Button>
          </div>
        )}
      </div>
    </div>
  )
}
